import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader/loader.service';

@Component({
  selector: 'vex-app-loader',
  standalone: true,
  template: ` <div
    *ngIf="isLoading && !(loaderService.isLoading$ | async)"
    class="loader-overlay relative">
    <div id="loader" class="text-center">
      <div class="loader mx-auto mb-4">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="text-black text-base">One moment please...</p>
    </div>
  </div>`,
  imports: [NgIf, AsyncPipe],
  styles: [
    `
      /* loader.component.css */
      .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* semi-transparent black */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1200;
        /* Ensure it's above other content */
      }
    `
  ]
})
export class AppLoaderComponent implements OnDestroy {
  loaderService = inject(LoaderService);
  @Input() isLoading: boolean = true;

  ngOnDestroy(): void {
    this.isLoading = true;
  }
}
