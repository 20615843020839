import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Location } from '@angular/common';
import { Observable, BehaviorSubject, of, firstValueFrom } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap
} from 'rxjs/operators';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { parseResponse } from './helpers';
import { environment } from 'src/assets/environments/environment.prod';
import { ConfirmDialogComponent } from '../layouts/components/confirm-dialog/confirm-dialog.component';
import { Toast, ToastrService } from 'ngx-toastr';
import {
  VexColorScheme,
  VexConfigName
} from '@vex/config/vex-config.interface';
import { VexConfigService } from '@vex/config/vex-config.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import { ExportToCsv } from 'export-to-csv';
import { SelectFormVariantComponent } from '../pages/master-data/form-type/component/select-form-variant/select-form-variant.component';
import { ViewPdfComponent } from '../pages/master-data/program-config/components/view-pdf/view-pdf.component';
import { PDFDocument, PDFTextField } from 'pdf-lib';
import { ImageCrapResizeComponent } from './shared/image-crap-resize/image-crap-resize.component';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
// Set the workerSrc for pdf.js
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  require('pdfjs-dist/package.json').version
}/pdf.worker.js`;
import * as fontkit from 'fontkit';
import { MultiEmailEntryComponent } from './shared/multi-email-entry/multi-email-entry.component';
import { CommonNotesDialogComponent } from './shared/common-notes-dialog/common-notes-dialog.component';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { CommonDateDialogComponent } from './shared/common-date-dialog/common-date-dialog.component';
import { LoaderService } from '../service/loader/loader.service';

// Cast fontkit to 'any' to avoid TypeScript issues
const fontkitInstance = fontkit as any;
@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  // source for observable
  public userSettingsSource = new BehaviorSubject<any>(null);
  // observable stream
  public userSettings$ = this.userSettingsSource.asObservable();
  private userDispensarySource = new BehaviorSubject(null);
  public userDispensary$ = this.userDispensarySource
    .asObservable()
    .pipe(distinctUntilChanged());
  public tokenName = 'ng-token';
  public roleID = 'role';
  public categoriesList = [];
  public shiftTimingList = [];
  public salesTypeList = [];
  public orderStatusList = [];
  public prodCategoriesList = [];
  public metrcCategoriesList = [];
  public stainsList = [];
  public prodSubCategoriesList = [];
  public uomList = [];
  public menuList = [];
  public productMenu = [];
  public menuItems = [];
  public planMenuItems = [];
  public rolesList = [];
  public staffRolesList = [];
  public licenseList = [];
  public locationList = [];
  public brandList = [];
  public tagList = [];
  public dispensaryList = [];
  public passwordWarningMessage =
    'Password should have 8 characters and must include at least one uppercase, one lowercase, a numeric number 0-9, and a special character ! @  # $ % ^ & * - ?';
  constructor(
    private http: HttpClient,
    protected localStorag: StorageMap,
    private location: Location,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private readonly configService: VexConfigService,
    private dialog: MatDialog,
    private loaderService: LoaderService
  ) {
    this.userSettingsSource = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('userProfile') || '{}')
    );
    this.userSettings$ = this.userSettingsSource.asObservable();
  }

  public setUserSettings(data: any) {
    this.userSettingsSource.next(data);
  }
  public setUserDispensary(data: any) {
    this.userDispensarySource.next(data);
  }
  public userData() {
    return this.userSettingsSource.value;
  }

  setUserToken(userInfo: any, token: string) {
    this.setUserRole(userInfo);

    return this.localStorag.set(this.tokenName, token);
  }
  setUserRole(userInfo: any) {
    let role = '';
    if (userInfo.role_id.id == 1) {
      role = 'ROLE_ADMIN';
    } else if (userInfo.role_id.id == 2) {
      role = 'ROLE_SUB_ADMIN';
    } else if (userInfo.role_id.id == 3 && !userInfo.company) {
      role = 'ROLE_ADMIN_STAFF';
    } else if (userInfo.role_id.id == 3 && userInfo.company) {
      role = 'ROLE_SUB_ADMIN_STAFF';
    }

    return localStorage.setItem('role', role);
  }

  getUserRole() {
    return localStorage.getItem(this.roleID);
  }

  getUserByID(id: any) {
    return this.http.get('user/profile/' + id).pipe(
      map((resp: any) => {
        let Users: any;
        if (resp && resp.data) {
          let userAttachment = resp.data?.attachments?.find(
            (i: any) => i.class == 'MyProfile'
          );
          let logo =
            environment.mediaUrl +
            userAttachment?.dir +
            '/' +
            userAttachment?.fileName;
          let data = { ...resp.data, userImgUrl: logo };
          Users = data;
        }
        return Users;
      })
    );
  }

  get isAdmin(): boolean {
    return localStorage.getItem('role') === 'ROLE_ADMIN';
  }
  get isDispensary(): boolean {
    return localStorage.getItem('role') === 'ROLE_USER';
  }
  get isStaff(): boolean {
    return localStorage.getItem('role') === 'ROLE_STAFF';
  }

  isHavePermission(resourcetype: string, actions: any) {
    const menu = this.menuList.find(
      (planMenu: any) => resourcetype === planMenu.state
    );
    // const menu = this.menuList.find((planMenu) => {
    //   let flag = false;
    //   if (planMenu.type === 'link') {
    //     flag = resourcetype === planMenu.state;
    //   } else {
    //     planMenu.children.map((child) => {
    //       flag = child.state === resourcetype;
    //     });
    //   }
    //   return flag ? planMenu : false;
    // });
    return (
      this.isAdmin ||
      this.isDispensary ||
      (this.isStaff && menu && menu[actions])
    );
  }

  getUserByToken() {
    return this.http.get(environment.apiUrl + '/me/users').pipe(
      map((resp: any) => {
        let user: any;
        if (resp.data) {
          const rawData = resp.data;
          if (rawData && rawData.id) {
            user = rawData;
          }
          this.setUserSettings(user);
        }

        return user;
      })
    );
  }

  /**
   * isAdmin
   */
  public getLoggedinUserId(): boolean {
    let id = null;
    if (
      this.userSettingsSource.value &&
      this.userSettingsSource.value.role_id
    ) {
      id = this.userSettingsSource.value.id;
    }
    return id;
  }

  /**
   * showSuccessMessage
   */
  public showSuccessMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      timeout: 3000,
      verticalPosition: 'top'
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', {
    //   duration: options.timeout,
    //   verticalPosition: options.verticalPosition
    // });
  }

  /**
   * showErrorMessage
   */
  public showErrorMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      duration: 3000,
      verticalPosition: 'top'
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', options);
  }

  /**
   * showErrorMessage
   */
  public showWarningMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      timeout: 3000
    };

    options = { ...defaultOptions, ...options };

    // this.snackbar.open(message, 'OK', {
    //   duration: options.timeout
    // });
  }

  public queryStringFormat(queryParams: any) {
    const reqParams: any = {
      offset:
        queryParams.pageNumber > 0
          ? queryParams.pageNumber * queryParams.pageSize
          : 0,
      limit: queryParams.pageSize || '',
      sortField: 'id',
      sortOrder: 'DESC'
    };

    if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
      reqParams.filter = queryParams.filter;
    }

    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (queryParams.sortField && queryParams.sortOrder) {
      reqParams.sortOrder = queryParams.sortOrder;
      reqParams.sortField = queryParams.sortField;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(reqParams[k]);
      })
      .join('&');

    return queryString;
  }
  public queryStringFormatWithoutOffset(queryParams: any) {
    let req: any;

    if (queryParams && Object.keys(queryParams).length > 0) {
      req = JSON.parse(queryParams.filter);
    }

    let reqParams: any = {};
    if (req?.where?.type) {
      reqParams.type = `${req?.where?.type}`;
    }
    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (req?.where?.processFlow) {
      reqParams.processFlow = `${req?.where?.processFlow}`;
    }

    if (req?.where?.processFlowId) {
      reqParams.id = `${req?.where?.processFlowId}`;
    }
    if (req?.where?.userId) {
      reqParams.userId = `${req?.where?.userId}`;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = `${req?.where?.programConfigId}`;
    }
    if (req?.where?.hasOwnProperty('is_delete')) {
      reqParams.is_delete = false;
    }
    if (req?.where?.hasOwnProperty('isCompany')) {
      reqParams.isCompany = req?.where?.isCompany;
    }
    if (req?.where?.companyId) {
      reqParams.companyId = req?.where?.companyId;
    }
    if (req?.where?.formIds) {
      reqParams.formIds = req?.where?.formIds;
    }
    if (req?.where?.childTableName) {
      reqParams.childTableName = req?.where?.childTableName;
    }
    if (req?.where?.sampleId) {
      reqParams.sampleId = req?.where?.sampleId;
    }

    if (req?.where?.isCQARole) {
      reqParams.isCQA = req?.where?.isCQARole;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = req?.where?.programConfigId;
    }

    if (req?.where?.isFormulatorBlender) {
      reqParams.isFormulatorBlender = req?.where?.isFormulatorBlender;
    }
    if (req?.where?.isRebrander) {
      reqParams.isRebrander = req?.where?.isRebrander;
    }
    if (req?.where?.FormulationType) {
      reqParams.FormulationType = req?.where?.FormulationType;
    }
    if (req?.where?.BrandApprove) {
      reqParams.BrandApprove = req?.where?.BrandApprove;
    }
    if (req?.where?.isDraft) {
      reqParams.isDraft = req?.where?.isDraft;
    }

    if (req?.where?.isIFC05) {
      reqParams.isIFC05 = req?.where?.isIFC05;
    }
    if (req?.where?.renewalNoticeId) {
      reqParams.renewalNoticeId = req?.where?.renewalNoticeId;
    }
    if (req?.where?.Form09Approve) {
      reqParams.Form09Approve = req?.where?.Form09Approve;
    }
    if (req?.where?.isRenewed) {
      reqParams.isRenewed = req?.where?.isRenewed;
    }

    if (req?.where?.isBlender) {
      reqParams.isBlender = req?.where?.isBlender;
    }

    if (req?.where?.sampleId) {
      reqParams.sampleId = req?.where?.sampleId;
    }

    if (req?.where?.renewApplied) {
      reqParams.renewApplied = req?.where?.renewApplied;
    }
    if (req?.where?.formulationApprovalCode) {
      reqParams.formulationApprovalCode = req?.where?.formulationApprovalCode;
    }
    if (req?.where?.annualRenewalNoticeId) {
      reqParams.annualRenewalNoticeId = req?.where?.annualRenewalNoticeId;
    }
    if (req?.where?.status) {
      reqParams.status = req?.where?.status;
    }
    if (req?.where?.RenewalId) {
      reqParams.RenewalId = req?.where?.RenewalId;
    }

    if (req?.where?.formulationType) {
      reqParams.formulationType = req?.where?.formulationType;
    }
    if (req?.where?.TestStatus) {
      reqParams.TestStatus = req?.where?.TestStatus;
    }

    if (req?.where?.isActive) {
      reqParams.isActive = req?.where?.isActive;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  // public queryStringFormatWithoutOffset(queryParams: any) {
  //   let req: any;

  //   if (queryParams && Object.keys(queryParams).length > 0) {
  //     req = JSON.parse(queryParams.filter);
  //   }

  //   const reqParams: any = {};
  //   if (req?.where) {
  //     Object.entries(req.where).forEach(([key, value]) => {
  //       reqParams[key] = `${value}`;
  //     });
  //   }

  //   const queryString = Object.keys(reqParams)
  //     .map((k) => `${k}=${reqParams[k]}`)
  //     .join('&');

  //   return queryString;
  // }

  public queryStrFormat(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {
      limit: queryParams.pageSize,
      offset:
        queryParams.pageNumber > 0
          ? queryParams.pageNumber * queryParams.pageSize
          : 0
    };

    if (queryParams.sortField) {
      reqParams.sortField = queryParams.sortField;
    } else {
      reqParams.sortField = 'id';
    }

    if (queryParams.sortOrder) {
      reqParams.sortOrder = queryParams.sortOrder;
    } else {
      reqParams.sortOrder = 'DESC';
    }

    if (req?.where?.RebranderCompanyId) {
      reqParams.RebranderCompanyId = req?.where?.RebranderCompanyId;
    }
    if (req?.where?.BlenderCompanyId) {
      reqParams.BlenderCompanyId = req?.where?.BlenderCompanyId;
    }
    // if (queryParams.pageNumber == null || queryParams.pageNumber == 0) {
    //   reqParams.offset = 0;
    // } else {
    //   reqParams.offset = parseInt(queryParams.pageNumber) + 1;
    // }
    // if (queryParams.sortOrder == '') {
    //   reqParams.sort = `!id`;
    // }
    // if (queryParams.sortOrder == 'DESC') {
    //   reqParams.sort = `!${queryParams.sortField}`;
    // }
    // if (queryParams.sortOrder == 'ASC') {
    //   reqParams.sort = `${queryParams.sortField}`;
    // }
    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (req?.where?.isActive) {
      reqParams.isActive = `${req?.where?.isActive}`;
    }

    if (req?.where?.room_id) {
      reqParams.room_id = `${req?.where?.room_id}`;
    }
    if (req?.where?.cash_transactions) {
      reqParams.createdAt = `~${req?.where?.cash_transactions}`;
    }
    if (req?.where?.user_id) {
      reqParams.user_id = req.where.user_id;
    }
    if (req?.where?.vendor_id) {
      reqParams.vendor_id = req.where.vendor_id;
    }
    if (req?.where?.category_id) {
      reqParams.category_id = req.where.category_id;
    }
    if (req?.where?.strain_id) {
      reqParams.strain_id = req.where.strain_id;
    }
    if (req?.where?.item_type) {
      reqParams.item_type = req.where.item_type;
    }
    if (req?.where?.from_register) {
      reqParams.from_register = req.where.from_register;
    }
    if (req?.where?.facility_license_number) {
      reqParams.facility_license_number = `~${req?.where?.facility_license_number}`;
    }
    if (req?.where?.state) {
      reqParams.state = req?.where?.state;
    }

    // if (req?.where?.state_id) {
    //     reqParams.StateId = req?.where?.state_id;
    // }

    if (req?.where?.order_id) {
      reqParams.user_id = req.where.order_id;
    }
    if (req?.where?.brand_id) {
      reqParams.brand_id = req.where.brand_id;
    }
    if (req?.where?.is_delete) {
      reqParams.is_delete = req.where.is_delete;
    }
    if (req?.where?.is_active) {
      reqParams.is_active = req.where.is_active;
    }

    if (req?.where?.is_delete == false) {
      reqParams.is_delete = req.where.is_delete;
    }

    if (req?.where?.cannabis_product == false) {
      reqParams.cannabis_product = req.where.cannabis_product;
    }

    if (req?.where?.is_brand) {
      reqParams.is_brand = req.where.is_brand;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }

    if (req?.where?.vendor_id) {
      reqParams.vendor_id = req.where.vendor_id;
    }

    if (req?.where?.is_metrc_item !== undefined) {
      reqParams.is_metrc_item = req.where.is_metrc_item;
    }
    if (req?.where?.is_cannabis) {
      reqParams.is_cannabis = req.where.is_cannabis;
    }
    if (req?.where?.is_brand) {
      reqParams.is_brand = req.where.is_brand;
    }

    if (req?.where?.check_in_type) {
      reqParams.check_in_type = req.where.check_in_type;
    }

    if (req?.where?.is_finished) {
      reqParams.is_finished = req.where.is_finished;
    }

    if (req?.where?.inventory_status_name) {
      reqParams.inventory_status_name = req.where.inventory_status_name;
    }

    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.delivery_config_id) {
      reqParams.delivery_config_id = req.where.delivery_config_id;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }
    if (req?.where?.cannabis_product) {
      reqParams.cannabis_product = req.where.cannabis_product;
    }

    if (req?.where?.dispensaryName) {
      reqParams.dispensary_name = req.where.dispensaryName;
    }

    // if (req.where.is_delete !== undefined) {
    //   reqParams.is_delete = req.where.is_delete;
    // } else {
    //   reqParams.is_delete = false;
    // }
    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.varient_id) {
      reqParams.varient_id = req.where.varient_id;
    }

    if (req?.where?.product_category_id) {
      reqParams.product_category_id = req.where.product_category_id;
    }

    // if (req?.where?.createdAt) {
    //   reqParams.createdAt = req.where.createdAt
    // }
    if (req?.where?.cash_edit_dispensary_id) {
      reqParams.dispensary_id = `$in0|${req.where.cash_edit_dispensary_id}`;
    }

    if (req?.where?.cash_denomination_dispensary_id) {
      reqParams.dispensary_id = `$in${req.where.cash_denomination_dispensary_id}`;
    } else if (req?.where?.cash_denomination_dispensary_id === 0) {
      reqParams.dispensary_id = 0;
    }
    if (req?.where?.is_vault) {
      reqParams.is_vault = req.where.is_vault;
    }
    if (req?.where?.is_open) {
      reqParams.is_open = req.where.is_open;
    }
    if (req?.where?.event) {
      reqParams.event = req.where.event;
    }
    if (req?.where?.createdAt) {
      reqParams.createdAt = req?.where?.createdAt;
    }
    if (req?.where?.placed_by) {
      reqParams.placed_by = req?.where?.placed_by;
    }
    if (req?.where?.customer_id) {
      reqParams.customer_id = req?.where?.customer_id;
    }
    if (req?.where?.queueDate) {
      reqParams.createdAt = `~${req?.where?.queueDate}`;
    }
    if (req?.where?.currentDate) {
      reqParams.createdAt = `~${req?.where?.currentDate}`;
    }
    if (req?.where?.dispensaryAndAdmin) {
      reqParams.dispensary_id = `$in${req?.where?.dispensaryAndAdmin}|0`;
    }
    if (req?.where?.guest_type) {
      if (req?.where?.guest_type !== 'all') {
        const dynamicKey = req.where.guest_type; // Use the value of req.where.guest_type as the dynamic key
        reqParams[dynamicKey] = '!';
      }
    }
    if (req?.where?.to_register) {
      reqParams.to_register = req.where.to_register;
    }
    if (req?.where?.staff_id) {
      reqParams.staff_id = req.where.staff_id;
    }
    if (req?.where?.stock_quantity) {
      reqParams.stock_quantity = `>0`;
    }
    if (req?.where?.inventory_type) {
      reqParams.type = req?.where?.inventory_type;
    }
    if (req?.where?.type) {
      reqParams.type = `${req?.where?.type}`;
    }

    if (req?.where?.processFlow) {
      reqParams.processFlow = `${req?.where?.processFlow}`;
    }

    if (req?.where?.userId) {
      reqParams.userId = `${req?.where?.userId}`;
    }
    if (req?.where?.programConfigId) {
      reqParams.programConfigId = req?.where?.programConfigId;
    }
    if (req?.where?.sampleType) {
      reqParams.sampleType = `${req?.where?.sampleType}`;
    }
    if (req?.where?.hasOwnProperty('is_delete')) {
      reqParams.is_delete = req?.where?.is_delete;
    }
    if (req?.where?.hasOwnProperty('isCompany')) {
      reqParams.isCompany = req?.where?.isCompany;
    }
    if (req?.where?.companyId || req?.where?.companyId == 0) {
      reqParams.companyId = req?.where?.companyId;
    }
    if (req?.where?.paymentMethod) {
      reqParams.paymentMethod = req?.where?.paymentMethod;
    }
    if (req?.where?.formIds) {
      reqParams.formIds = req?.where?.formIds;
    }

    if (req?.where?.formType) {
      reqParams.formType = req?.where?.formType;
    }
    if (req?.where?.agreementId) {
      reqParams.agreementId = req?.where?.agreementId;
    }
    if (req?.where?.TestStatus) {
      reqParams.TestStatus = req?.where?.TestStatus;
    }
    if (req?.where?.SampleRecDate) {
      reqParams.SampleRecDate = req?.where?.SampleRecDate;
    }
    if (req?.where?.SampleSubDate) {
      reqParams.SampleSubDate = req?.where?.SampleSubDate;
    }
    if (req?.where?.TestCompletionDate) {
      reqParams.TestCompletionDate = req?.where?.TestCompletionDate;
    }
    if (req?.where?.CoProgramRole) {
      reqParams.CoProgramRole = req?.where?.CoProgramRole;
    }
    if (req?.where?.isCompleted) {
      reqParams.isCompleted = req?.where?.isCompleted;
    }
    if (req?.where?.isFieldAudit) {
      reqParams.isFieldAudit = req?.where?.isFieldAudit;
    }
    if (req?.where?.uploadToWebsite) {
      reqParams.uploadToWebsite = req?.where?.uploadToWebsite;
    }

    if (req?.where?.isCQARole) {
      reqParams.isCQA = req?.where?.isCQARole;
    }

    if (req?.where?.purposePayment) {
      reqParams.purpose = req?.where?.purposePayment;
    }
    if (req?.where?.BrandApprove) {
      reqParams.BrandApprove = req?.where?.BrandApprove;
    }

    if (req?.where?.Form02Status) {
      reqParams.Form02Status = req?.where?.Form02Status;
    }
    if (req?.where?.isIFC05) {
      reqParams.isIFC05 = req?.where?.isIFC05;
    }
    if (req?.where?.is_deleted) {
      reqParams.is_deleted = req?.where?.is_deleted;
    }
    if (req?.where?.companyID) {
      reqParams.companyID = req?.where?.companyID;
    }
    if (req?.where?.companyID || req?.where?.companyID == 0) {
      reqParams.companyID = req?.where?.companyID;
    }
    if (req?.where?.Production) {
      reqParams.Production = req?.where?.Production;
    }
    if (req?.where?.paymentStatus) {
      reqParams.paymentStatus = req?.where?.paymentStatus;
    }

    if (req?.where?.isDeleted) {
      reqParams.isDeleted = req?.where?.isDeleted;
    }
    if (req?.where?.LabelApprove) {
      reqParams.LabelApprove = req?.where?.LabelApprove;
    }
    if (req?.where?.searchBy) {
      reqParams.searchBy = req?.where?.searchBy;
    }
    if (req?.where?.isFormulatorBlender) {
      reqParams.isFormulatorBlender = req?.where?.isFormulatorBlender;
    }
    if (req?.where?.isRebrander) {
      reqParams.isRebrander = req?.where?.isRebrander;
    }
    if (req?.where?.isBlender) {
      reqParams.isBlender = req?.where?.isBlender;
    }

    if (req?.where?.searchString) {
      reqParams.searchString = req?.where?.searchString;
    }

    if (req?.where?.RebranderCompanyId) {
      reqParams.RebranderCompanyId = req?.where?.RebranderCompanyId;
    }
    if (req?.where?.BlenderCompanyId) {
      reqParams.BlenderCompanyId = req?.where?.BlenderCompanyId;
    }
    if (req?.where?.SpecName) {
      reqParams.SpecName = req?.where?.SpecName;
    }
    if (req?.where?.VisGrade) {
      reqParams.VisGrade = req?.where?.VisGrade;
    }

    if (req?.where?.FormulationType) {
      reqParams.FormulationType = req?.where?.FormulationType;
    }
    if (req?.where?.formulationType) {
      reqParams.formulationType = req?.where?.formulationType;
    }

    if (req?.where?.TestStatusNot) {
      reqParams.TestStatusNot = req?.where?.TestStatusNot;
    }

    if (req?.where?.type) {
      reqParams.type = req?.where?.type;
    }
    if (req?.where?.sampleId) {
      reqParams.sampleId = req?.where?.sampleId;
    }

    if (req?.where?.brandId) {
      reqParams.brandId = req?.where?.brandId;
    }
    if (req?.where?.annualRenewalNoticeId) {
      reqParams.annualRenewalNoticeId = req?.where?.annualRenewalNoticeId;
    }
    if (req?.where?.BrandStatus) {
      reqParams.BrandStatus = req?.where?.BrandStatus;
    }
    if (req?.where?.FormStatus) {
      reqParams.FormStatus = req?.where?.FormStatus;
    }
    if (req?.where?.ViscosityGrade) {
      reqParams.ViscosityGrade = req?.where?.ViscosityGrade;
    }
    if (req?.where?.Specification) {
      reqParams.Specification = req?.where?.Specification;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  public queryStrFormatSearch(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {};

    if (queryParams.sortOrder == '') {
      reqParams.sort = `!id`;
    }
    if (queryParams.sortOrder == 'DESC') {
      reqParams.sort = `!${queryParams.sortField}`;
    }
    if (queryParams.sortOrder == 'ASC') {
      reqParams.sort = `${queryParams.sortField}`;
    }
    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (req?.facility_license_number) {
      reqParams.facility_license_number = `~${req.facility_license_number}`;
    }
    if (req?.where?.varient_id) {
      reqParams.varient_id = req.where.varient_id;
    }

    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.customer_id) {
      reqParams.customer_id = req?.where?.customer_id;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }
  public queryStrFormat1(queryParams: any) {
    var req;
    if (Object.keys(queryParams?.filter).length > 0) {
      req = JSON.parse(queryParams.filter);
    }
    const reqParams: any = {};
    if (req?.where?.currentDate) {
      // reqParams.createdAt = '>=' + req?.where?.currentDate;
      reqParams.createdAt = `>=${req?.where?.currentDate}`;
    }
    if (req?.where?.staff_id) {
      reqParams.staff_id = req.where.staff_id;
    }
    if (req?.where?.dispensary_id) {
      reqParams.dispensary_id = req.where.dispensary_id;
    }
    if (req?.where?.is_delete == false) {
      reqParams.is_delete = req.where.is_delete;
    }
    if (req?.where?.order_id) {
      reqParams.order_id = req.where.order_id;
    }
    if (req?.where?.user_id) {
      reqParams.user_id = req.where.user_id;
    }
    if (req?.where?.room_type_id) {
      reqParams.room_type_id = req.where.room_type_id;
    }
    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return k + '=' + reqParams[k];
      })
      .join('&');

    return queryString;
  }

  onGoBack() {
    this.location.back();
  }

  //on Refresh
  private refreshList = new BehaviorSubject<boolean>(false);

  getListObservable() {
    return this.refreshList.asObservable();
  }

  triggerListRefresh() {
    this.refreshList.next(true);

    setTimeout(() => {
      this.refreshList.next(false);
    }, 300);
  }

  /**
   * getPhotos
   */
  public getMedia(queryParams: any) {
    let params = this.queryStringFormat(queryParams);
    return this.http.get('attachments?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };
        if (resp && Array.isArray(resp.data.rows)) {
          for (const item of resp.data.rows) {
            if (item) {
              if (item.file_name.split('.')[1] == 'mp4') {
                // item.isVideo = true
                item.video = environment.mediaUrl + item.dir + item.file_name;
              } else {
                // item.isImage = true
                item.image = environment.mediaUrl + item.dir + item.file_name;
              }
              retData.items.push(item);
            }
          }
          retData.totalCount = resp.data.count;
        }
        return retData;
      })
    );
  }

  public confirm(
    title?: string,
    message?: string,
    confirmLabel: string = 'OK',
    cancelLabel: string = 'Cancel',
    confirmActionColor?: any,
    iconShow?: boolean,
    iconName?: any,
    iconColor?: any,
    confirmShow?: boolean,
    cancelShow?: boolean,
    byPass?: boolean
  ): Promise<boolean> {
    // icon

    iconShow = iconShow ? iconShow : true;
    iconName = iconName ? iconName : 'heroicons_outline:exclamation';
    iconColor = iconColor ? iconColor : 'warn';

    //Confirm
    confirmShow = confirmShow == false ? false : true;
    confirmLabel = confirmLabel ? confirmLabel : 'OK';
    confirmActionColor = confirmActionColor ? confirmActionColor : 'warn';

    //Cancel
    cancelShow = cancelShow == false ? false : true;
    cancelLabel = cancelLabel ? cancelLabel : 'Cancel';

    //byPass
    byPass = byPass ? byPass : false;

    let dialogConfig: ConfirmationConfig = {
      title: title,
      message: message,
      icon: {
        show: iconShow,
        name: iconName,
        color: iconColor
      },
      actions: {
        confirm: {
          show: confirmShow,
          label: confirmLabel,
          color: confirmActionColor
        },
        cancel: {
          show: cancelShow,
          label: cancelLabel
        }
      },
      byPass: byPass,
      dismissible: true
    };
    return new Promise((resolve, reject) => {
      const modalRef = this.matDialog.open(ConfirmDialogComponent, {
        data: dialogConfig,
        autoFocus: false
      });
      modalRef.afterClosed().subscribe((result) => {
        if (!result) {
          resolve(false); // pass close
        } else {
          resolve(result); // pass confirm
        }
      });
    });
  }

  //handle error messages
  handleError(err: any) {
    if (err.message && typeof err.message === 'object') {
      const message = err.message;
      for (const key in message) {
        if (message.hasOwnProperty(key)) {
          this.toastr.error(message[key]);
        }
      }
    } else if (err.message) {
      this.toastr.error(err.message);
    }
  }

  //Color theme
  setConfig(layout: VexConfigName, colorScheme: VexColorScheme): void {
    this.configService.setConfig(layout);
    this.configService.updateConfig({
      style: {
        colorScheme
      }
    });
  }
  postAttachment(data: any) {
    return this.http.post('attachment', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {}
      )
    );
  }
  // convert innerhtml tags to text
  stripHtmlTags(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  public exportCSV(rows: any, fileName?: any) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: fileName,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: fileName
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(rows);
  }

  viewForm(form: any, formName: string = 'Form') {
    let dialogRef: MatDialogRef<ViewPdfComponent>;
    dialogRef = this.dialog.open(ViewPdfComponent, {
      width: '80%',
      data: {
        src: form,
        title: formName
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {});
  }

  formatToUSD(value: number): string {
    // Format the number as a currency string in USD format
    //return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} USD`;
    return `$${Math.floor(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} USD`;
  }
  formatToUSDWithoutFr(value: string): string {
    // Format the number as a currency string in USD format
    return Number(value).toLocaleString('en-US');
  }

  async flattenPdf(blob: Blob, isApprove = false): Promise<Blob> {
    // Convert the Blob into ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer();

    // Load the existing PDF document
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    // Register fontkit
    pdfDoc.registerFontkit(fontkitInstance);
    // Fetch and embed the custom fonts
    // todo:fonts
    const arialBoldFontBytes = await fetch(
      'https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/h3DpiixgPp.ttf'
    ).then((res) => res.arrayBuffer());
    const arialFontBytes = await fetch(
      'https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/dev/4P3ogTS6XI.ttf'
    ).then((res) => res.arrayBuffer());
    const newCursiveFontBytes = await fetch(
      'https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/staging/juRkImZw2Y.ttf'
    ).then((res) => res.arrayBuffer());

    // Embed fonts into the PDF document
    const arialBoldFont = await pdfDoc.embedFont(arialBoldFontBytes);
    const arialFont = await pdfDoc.embedFont(arialFontBytes);
    const newCursiveFont = await pdfDoc.embedFont(newCursiveFontBytes);
    // Get all form fields (if there are any)
    const form = pdfDoc.getForm();
    if (form) {
      // Get all fields and set the font for each one
      const fields = form.getFields();

      for (const field of fields) {
        const textField = field as PDFTextField;

        // Get the field name
        const fieldName = textField.getName().trim().toLowerCase();

        // Use async/await to load the font and update the appearance for each field
        if (fieldName === 'co02signature') {
          try {
            // Save the current text value of the field
            const currentText = textField.getText();

            // Optionally, if you need to set text, you can call setText (though it's not always necessary)
            textField.setText(currentText); // This may not be needed, but it's a safeguard
            // Assuming newCursiveFont is already loaded asynchronously before this
            textField.updateAppearances(newCursiveFont); // Apply cursive font
          } catch (error) {
            console.error(`Failed to apply font to ${fieldName}:`, error);
          }
        }

        if (fieldName === 'cqa02signature' && isApprove) {
          textField.setText('Rebecca Cox'); // This may not be needed, but it's a safeguard
          // Assuming newCursiveFont is already loaded asynchronously before this
          textField.updateAppearances(newCursiveFont); // Apply cursive font
        }
      }

      // Flatten the form fields into static text
      form.flatten();
    }

    // Serialize the PDFDocument to bytes (Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Create a new Blob with the flattened content
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  public async updatePdfFormFields(
    pdfUrl: string,
    formData: Object
  ): Promise<any> {
    try {
      if (!pdfUrl) {
        return;
      }
      const headers = new HttpHeaders({
        'Content-Type': 'application/pdf' // Ensure this header is appropriate
      });

      // Fetch the existing PDF document
      const existingPdfBytes: any = await this.http
        .get(pdfUrl, {
          headers,
          responseType: 'arraybuffer'
        })
        .toPromise(); // Use toPromise for cleaner async/await handling

      // Load the PDF document

      let pdfBytes: any;
      // Update form fields
      if (formData && Object.keys(formData).length > 0) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        // Get the form fields
        const form: any = pdfDoc.getForm();
        for (const [fieldName, fieldValue] of Object.entries(formData)) {
          try {
            const field: any = form.getField(fieldName);
            if (field) {
              // Check if licenseFee exists in formData

              if (field instanceof PDFTextField) {
                if (
                  typeof fieldValue === 'string' ||
                  typeof fieldValue === 'undefined'
                ) {
                  field.setText(fieldValue ?? ''); // Use empty string if fieldValue is undefined
                } else {
                  // console.warn(
                  //   `Field value for "${fieldName}" should be a string or undefined.`
                  // );
                }
              } else if (field.constructor.name === 'PDFCheckbox') {
                // Example: Check if the value should be checked
                if (fieldValue === true || fieldValue === 'true') {
                  field.check();
                } else {
                  field.uncheck();
                }
              } else if (field.constructor.name === 'PDFRadioButton') {
                // Handle radio buttons if necessary
                // Example: set the selected radio button based on fieldValue
              } else {
                // console.warn(`Field "${fieldName}" is of unsupported type.`);
              }
            } else {
              // console.warn(
              //   `Field with name "${fieldName}" not found in the PDF.`
              // );
            }
            // if (fieldName == 'LicenseFee' ||  fieldName == 'LicenseRenewalFee') {
            if (
              (fieldName === 'LicenseFee' ||
                fieldName === 'LicenseRenewalFee') &&
              fieldValue
            ) {
              // Disable the field if licenseFee is present
              //field.disable();
              fieldValue.setReadOnly(true);
            }
          } catch (error) {
            // console.error(`Error processing field "${fieldName}":`, error);
          }
        }
        // Serialize the PDF document to bytes
        pdfBytes = await pdfDoc.save();
      } else {
        pdfBytes = existingPdfBytes;
      }

      // Create a Blob and create an object URL
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return blob;
      // const url = URL.createObjectURL(blob);
      // // Open the PDF in a new tab
      // window.open(url, '_blank');
      // // Clean up the object URL after use
      // URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error updating PDF form fields:', error);
    }
  }

  /**
   * Opens a dialog for image cropping and resizing.
   *
   * This method opens a dialog window using the MatDialog service to display the ImageCrapResizeComponent.
   * The dialog window allows the user to crop and resize an image.
   *
   * @returns {Promise}
   */
  async onCallImageCrapResize(
    cFiles: any[],
    croppedImages: any[]
  ): Promise<any> {
    return new Promise((resolve) => {
      let dialogRef: MatDialogRef<ImageCrapResizeComponent>;
      dialogRef = this.dialog.open(ImageCrapResizeComponent, {
        data: {
          cFiles: cFiles,
          croppedImages: croppedImages
        },
        width: 'auto'
      });

      dialogRef.afterClosed().subscribe((resp) => {
        resolve(resp);
      });
    });
  }

  /**
   * Flattens the form fields of a PDF document located at the specified URL.
   *
   * @param pdfUrl - The URL of the PDF document to be flattened.
   * @returns A Promise that resolves to a Blob containing the flattened PDF document.
   * @throws If there is an error updating the PDF form fields.
   */
  async flattenPdfUrl(pdfUrl: string): Promise<any> {
    try {
      if (!pdfUrl) {
        return;
      }
      const headers = new HttpHeaders({
        'Content-Type': 'application/pdf' // Ensure this header is appropriate
      });

      // Fetch the existing PDF document
      const existingPdfBytes: any = await this.http
        .get(pdfUrl, {
          headers,
          responseType: 'arraybuffer'
        })
        .toPromise();

      // Load the existing PDF document
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Get all form fields (if there are any)
      const form = pdfDoc.getForm();
      if (form) {
        // Flatten the form fields into static text
        form.flatten();
      }

      // Serialize the PDFDocument to bytes (Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Create a new Blob with the flattened content
      return new Blob([pdfBytes], { type: 'application/pdf' });
    } catch (err) {
      console.error('Error updating PDF form fields:', err);
    }
  }

  async extractHtmlFromPdf(filePath: string): Promise<string> {
    try {
      const loadingTask = getDocument(filePath);
      const pdf = await loadingTask.promise;
      const totalPages = pdf.numPages;

      const htmlContent: string[] = [];

      for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
          // Ensure context is not null
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({
            canvasContext: context as CanvasRenderingContext2D,
            viewport
          }).promise;

          const imgData = canvas.toDataURL('image/png');
          htmlContent.push(
            `<div class="pdf-page"><img src="${imgData}" alt="Page ${pageNum}"/></div>`
          );
        } else {
          console.error(`Failed to get canvas context for page ${pageNum}`);
        }
      }

      return htmlContent.join('');
    } catch (error) {
      console.error('Error extracting PDF to HTML:', error);
      return '';
    }
  }

  onGetCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.toLocaleString('en-US', { month: 'long' }); // Get full month name
    const day = today.getDate().toString().padStart(2, '0'); // Pad day with leading zero if needed
    return `${year} ${month} ${day}`; // Format date as YYYY-Month-DD
  }

  getStyles(): string {
    return `
    .arialFontStyle {
  font-family: "arial" !important;
}
@font-face {
  font-family: "ArialBold";
  src: url("https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/h3DpiixgPp.ttf")
    format("truetype");
}
@font-face {
  font-family: "arial";
  src: url("https://s3.us-west-2.amazonaws.com/cdn.fluidquality.com/dev/4P3ogTS6XI.ttf");
}
.ifc09 {
  width: 693px;
  padding: 25px 20px;
  margin: auto;
}

.ifc09content {
  padding: 20px;
}

.s1 {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
}

h1 {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

h2 {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.p,
p {
  font-family: Arial, sans-serif !important;
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.s2 {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s3 {
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 11pt;
}

.s5 {
  color: black;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
}

.s7 {
  color: #1f3863;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
}

.s8 {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
}

.s9 {
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}

table,
tbody {
  vertical-align: top;
  overflow: visible;
  font-family: Arial, sans-serif !important;
}

    `;
  }

  /**
   * Generates a 16-digit UUID-like string.
   * @returns {string} A 16-digit string composed of timestamp and random numbers.
   */
  generate16DigitUUID(): string {
    // Get current timestamp in milliseconds
    const timestamp = Date.now().toString();

    // Generate 8 random digits, padded with leading zeros if necessary
    const randomPart = Math.floor(Math.random() * 1e8)
      .toString()
      .padStart(8, '0');

    // Combine timestamp and random part, then slice to ensure 16 digits
    return (timestamp + randomPart).slice(0, 16);
  }

  // Helper function to format the value
  formatValue = (input: any): string => {
    // Check if input is null, undefined, or an empty string
    if (input == undefined || input == null || input === '') {
      return ''; // Return empty string for null, undefined, or empty input
    }

    const trimmedInput = input.toString().trim(); // Ensure input is a string and trim whitespace

    // Check if the trimmed input is a valid number
    if (!isNaN(Number(trimmedInput))) {
      const num = Number(trimmedInput);

      // If the number is exactly 0, return it as '0' without decimal places
      if (num === 0) {
        return '0';
      }

      // Format all other valid numbers to 2 decimal places
      return num.toFixed(2).replace(/\.00$/, ''); // Remove unnecessary `.00` for whole numbers
    }

    // For invalid numbers, return the original trimmed input
    return trimmedInput;
  };

  /**
   * Converts a PDF URL to a Blob object.
   * @param pdfUrl - The URL of the PDF file to be converted.
   * @returns A Promise that resolves to a Blob containing the PDF data, or undefined if the URL is invalid.
   */
  async PdfUrlToBlob(pdfUrl: string): Promise<any> {
    try {
      console.log(pdfUrl);
      // Check if the provided URL is valid
      if (!pdfUrl) {
        return undefined;
      }

      // Set up headers for the HTTP request
      const headers = new HttpHeaders({
        'Content-Type': 'application/pdf' // Specify that we're expecting a PDF
      });

      // Fetch the existing PDF document
      const existingPdfBytes: any = await this.http
        .get(pdfUrl, {
          headers,
          responseType: 'arraybuffer' // Request the response as binary data
        })
        .toPromise();

      // Convert the array buffer to a Blob
      return new Blob([existingPdfBytes], { type: 'application/pdf' });
    } catch (err) {
      // Log any errors that occur during the process
      console.error('Error updating PDF form fields:', err);
      return undefined;
    }
  }

  downloadPdf(bufferData: string, fileName: string = 'form') {
    // Assuming you have received the Base64 buffer from the API
    const base64Buffer = bufferData; // Replace with the actual Base64 string

    // Decode the Base64 string to get the binary data
    const byteCharacters = atob(base64Buffer);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // Step 1: Create a Blob from the buffer data
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Step 2: Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    // Check if the filename already ends with '.pdf'
    if (!fileName.endsWith('.pdf')) {
      fileName += '.pdf'; // Add '.pdf' if not already present
    }

    console.log('fileName :>> ', fileName);
    link.download = fileName; // Set the filename for the download

    // Step 3: Append the link to the body and simulate a click
    document.body.appendChild(link);
    link.click();

    // Step 4: Remove the link from the DOM
    document.body.removeChild(link);
  }

  getAdminEmails(): Observable<any> {
    return this.http.get('user/admin-emails').pipe(
      map((resp: any) => {
        let data: any = [];
        if (resp && resp.data) {
          data = resp.data;
        }
        return data;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }

  /**
   * Converts a Base64 encoded string to a Uint8Array buffer.
   * @param bufferData The Base64 encoded string to convert
   * @returns A blobs containing the decoded byteArray data
   */
  onConvertBase64StrToBuffer(bufferData: string): Uint8Array {
    // Assuming bufferData is the Base64 string received from the API
    const base64Buffer = bufferData;

    // Decode the Base64 string to get the binary data
    const byteCharacters = atob(base64Buffer);
    const byteNumbers = new Array(byteCharacters.length);

    // Convert each character to its ASCII code
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Create and return a blob from the byte numbers
    let byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], { type: 'application/pdf' });
    return byteArray;
  }

  /**
   * Converts an Image URL to a Blob object.
   * @param imageUrl - The URL of the image file to be converted.
   * @returns A Promise that resolves to a Blob containing the image data, or undefined if the URL is invalid.
   */
  async ImageUrlToBlob(imageUrl: string): Promise<Blob | undefined> {
    try {
      console.log(imageUrl);

      // Check if the provided URL is valid
      if (!imageUrl) {
        return undefined;
      }

      // Extract the file extension from the URL to dynamically determine MIME type
      const fileExtension = imageUrl.split('.').pop()?.toLowerCase();
      let mimeType = 'application/octet-stream'; // Default MIME type for unknown file types

      // Dynamically set MIME type based on file extension
      if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
        mimeType = 'image/jpeg';
      } else if (fileExtension === 'png') {
        mimeType = 'image/png';
      } else if (fileExtension === 'gif') {
        mimeType = 'image/gif';
      } else if (fileExtension === 'bmp') {
        mimeType = 'image/bmp';
      } else if (fileExtension === 'webp') {
        mimeType = 'image/webp';
      }

      // Fetch the image from the URL as an arraybuffer (binary data)
      const imageBytes: any = await this.http
        .get(imageUrl, {
          responseType: 'arraybuffer' // Request the response as binary data
        })
        .toPromise();

      // Convert the array buffer to a Blob with the dynamically determined MIME type
      return new Blob([imageBytes], { type: mimeType });
    } catch (err) {
      // Log any errors that occur during the process
      console.error('Error converting image URL to Blob:', err);
      return undefined;
    }
  }

  /**
   * Converts a text file URL to a Blob object.
   * @param fileUrl - The URL of the text file to be converted.
   * @returns A Promise that resolves to a Blob containing the text file data, or undefined if the URL is invalid.
   */
  async TextFileUrlToBlob(fileUrl: string): Promise<Blob | undefined> {
    try {
      console.log(fileUrl);

      // Check if the provided URL is valid
      if (!fileUrl) {
        return undefined;
      }

      // Ensure the file has a .txt extension, but you can modify this if needed
      const fileExtension = fileUrl.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'txt') {
        throw new Error(
          'Invalid file extension. Only .txt files are supported.'
        );
      }

      // Set the MIME type for a text file
      const mimeType = 'text/plain';

      // Fetch the text file from the URL as an arraybuffer (binary data)
      const fileBytes: any = await this.http
        .get(fileUrl, {
          responseType: 'arraybuffer' // Request the response as binary data
        })
        .toPromise();

      // Convert the array buffer to a Blob with the text file MIME type
      return new Blob([fileBytes], { type: mimeType });
    } catch (err) {
      // Log any errors that occur during the process
      console.error('Error converting text file URL to Blob:', err);
      return undefined;
    }
  }

  /**
   * Opens a dialog for entering multiple email addresses.
   *
   * @param {any[]} emails - An optional array of initial email addresses.
   * @returns {Promise<any>} A promise that resolves with the dialog's response.
   */
  async onOpenMultipleEmails(emails: any = []): Promise<any> {
    return new Promise((resolve) => {
      // Open the MultiEmailEntryComponent dialog
      let dialogRef = this.dialog.open(MultiEmailEntryComponent, {
        width: '60%', // Set the dialog width to 60% of the screen
        data: {
          emails: emails || [] // Pass the provided emails or an empty array if none provided
        }
      });

      // Handle the dialog closure
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp) {
          resolve([resp]); // Resolve the promise with the dialog's response
        } else {
          resolve([]); // Resolve the promise with the dialog's response
        }
      });
    });
  }

  /**
   * Opens a dialog for entering common notes.
   *
   * @param {Object} details - An object containing details for the dialog.
   * @param {string} details.title - The title for the dialog.
   * @returns {Promise<any>} A promise that resolves with the dialog's response.
   */
  async onOpenCommonNotes(
    details: { title: string } = { title: '' }
  ): Promise<any> {
    return new Promise((resolve) => {
      // Open the CommonNotesDialogComponent dialog
      let dialogRef = this.dialog.open(CommonNotesDialogComponent, {
        width: '40%', // Set the dialog width to 40% of the screen
        data: details
      });

      // Handle the dialog closure
      dialogRef.afterClosed().subscribe((resp) => {
        resolve(resp); // Resolve the promise with the dialog's response
      });
    });
  }

  onGetIFCfileNames(type: string): string {
    switch (type) {
      case 'IFC02':
        return 'Form IFC02 License Agreement';
      case 'IFC03':
        return 'Form IFC03 - Application for IFC Formulation Approval';
      case 'IFC04':
        return '	Form IFC04 - Supplier Declaration for Rebranded Product';
      case 'IFC05':
        return 'Form IFC05 - Notice of Sample Submission';
      case 'IFC06':
        return '	Form IFC06 - Sample Data Report';
      case 'IFC07':
        return 'Form IFC07 - Application for Product Brand Name Registration & Modification';
      case 'IFC09':
        return 'Form IFC09 - Notice of Annual Renewal';
      case 'IFC10':
        return 'Form IFC10 - Notice of Expiration or Termination';
      default:
        return `View ${type}`;
    }
  }

  //SwalDialog with async for with content html without icon
  async SwalDialogContent(
    title: string,
    contentHtml: string,
    confirmButtonText: string,
    cancelButtonText?: string,
    showCancelButton: boolean = true,
    icon?: SweetAlertIcon // optional icon parameter
  ): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: contentHtml,
      customClass: {
        popup: 'custom-swal-container'
      },
      reverseButtons: true,
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText || '',
      icon: icon
    });

    return result.isConfirmed;
  }

  /**
   * Opens a dialog for selecting a common date.
   *
   * @param data - An object containing dialog configuration
   * @param data.title - The title of the dialog
   * @param data.DateLabel - The label for the date input field
   * @returns A Promise that resolves with the selected date or undefined if cancelled
   */
  async onOpenCommonDate(data: {
    title: string;
    DateLabel: string;
  }): Promise<any> {
    return new Promise((resolve) => {
      // Open the CommonDateDialogComponent dialog
      let dialogRef = this.dialog.open(CommonDateDialogComponent, {
        width: '40%', // Set the dialog width to 40% of the screen
        data: {
          title: data.title,
          DateLabel: data.DateLabel
        }
      });

      // Handle the dialog closure
      dialogRef.afterClosed().subscribe((resp) => {
        resolve(resp); // Resolve the promise with the dialog's response (selected date or undefined)
      });
    });
  }

  /**
   * Parses an email data string and extracts email addresses into a structured object.
   * @param {string} emailData - A JSON string containing email address information.
   * @returns {Object|null} An object with ToEmail, CcEmail, and BccEmail fields, or null if parsing fails.
   */
  getEmailObject(emailData: string) {
    try {
      // Parse the email string as JSON
      const parsedEmail = JSON.parse(emailData);
      // Extract the "to", "cc", and "bcc" email addresses, defaulting to empty arrays if not present
      const toEmails = parsedEmail.to || [];
      const ccEmails = parsedEmail.cc || [];
      const bccEmails = parsedEmail.bcc || [];
      // Join email addresses as comma-separated strings
      const toEmailString = toEmails.join(', ');
      const ccEmailString = ccEmails.join(', ');
      const bccEmailString = bccEmails.join(', ');
      // Create a new object with "ToEmail", "CcEmail", and "BccEmail" fields
      const emailObject = {
        ToEmail: toEmailString,
        CcEmail: ccEmailString,
        BccEmail: bccEmailString
      };
      return emailObject;
    } catch (error) {
      // Log the error and return null if parsing fails
      // console.error('Error parsing email data:', error);
      return {
        ToEmail: '-',
        CcEmail: '-',
        BccEmail: '-'
      };
    }
  }

  /**
   * Retrieves the email addresses of admin users.
   * @returns {Promise<string[]>} A promise that resolves to an array of admin email addresses.
   */
  async onGetAdminEmails(): Promise<string[]> {
    try {
      this.loaderService.show();
      // Fetch admin emails from the app settings service
      const adminEmails = await firstValueFrom(this.getAdminEmails());
      this.loaderService.hide();

      // Return the array of admin emails
      return adminEmails;
    } catch (err) {
      // Log any errors that occur during the process
      console.error('Error retrieving admin emails:', err);
      this.loaderService.hide();

      // Return an empty array if an error occurs
      return [];
    }
  }

  async onDownload(ev: any, fileName?: string): Promise<void> {
    const defaultFileName = 'Form.pdf'; // Provide a default name if needed
    fileName = fileName || defaultFileName; // Use the provided name or a default
    const fileUrl = ev;

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName; // You can set the desired filename here
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
    // }
  }

  /**
   * Displays a SweetAlert dialog with customizable options.
   *
   * @param options - An object containing the following properties:
   *   @param options.title - The title of the dialog.
   *   @param options.icon - The icon to display in the dialog (default: 'success').
   *   @param options.text - The message text to display in the dialog (default: '').
   *   @param options.confirmButtonText - The text for the confirm button (default: 'Ok').
   *   @param options.showCancelButton - Whether to show a cancel button (default: false).
   *   @param options.cancelButtonText - The text for the cancel button (default: 'Cancel').
   * @returns A Promise that resolves to `true` if the confirm button is clicked, otherwise `false`.
   */
  async simpleSwalDialog(options: {
    title: string;
    icon?: SweetAlertIcon;
    text?: string;
    confirmButtonText?: string;
    showCancelButton?: boolean;
    cancelButtonText?: string;
  }): Promise<boolean> {
    const {
      title,
      icon = 'success',
      text = '',
      confirmButtonText = 'Ok',
      showCancelButton = false,
      cancelButtonText = 'Cancel'
    } = options;
    // Display the SweetAlert dialog with the specified options
    const result = await Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      customClass: { popup: 'custom-swal-container' },
      reverseButtons: true
    });

    return result.isConfirmed;
  }
}

export interface UserSettingsInterface {
  id: number;
  name: string;
  email: string;
  isVerified: boolean;
  isActive: boolean;
  role_id: number;
}
export class QueryParamsModel {
  // fields
  filter: any;
  queryString: string;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;

  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder: string = 'asc',
    _sortField: string = '',
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _queryString: string = ''
  ) {
    this.filter = _filter;
    this.queryString = _queryString;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
  }
}

export interface ConfirmationConfig {
  title?: string;
  message?: string;
  icon?: {
    show?: boolean;
    name?: string;
    color?:
      | 'primary'
      | 'accent'
      | 'warn'
      | 'basic'
      | 'info'
      | 'success'
      | 'warning'
      | 'error';
  };
  actions?: {
    confirm?: {
      show?: boolean;
      label?: string;
      color?: 'primary' | 'accent' | 'warn';
    };
    cancel?: {
      show?: boolean;
      label?: string;
    };
  };
  byPass?: boolean;
  dismissible?: boolean;
}
