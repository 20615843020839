import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean | null>(false);
  isLoading$ = this.loadingSubject.asObservable();

  _isHideLoader = signal<boolean>(false);

  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
  }

  private fileUploadLoadingSubject = new BehaviorSubject<boolean | null>(false);
  fileUploadLoading$ = this.fileUploadLoadingSubject.asObservable();

  showFile() {
    this.fileUploadLoadingSubject.next(true);
  }

  hideFile() {
    this.fileUploadLoadingSubject.next(false);
  }

  hideLoader(value: boolean, string?: any): void {
    if (value) {
      setTimeout(() => {
        this._isHideLoader.set(value);
      }, 1000);
    } else {
      this._isHideLoader.set(value);
    }
  }
}
