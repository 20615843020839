import { NgIf, AsyncPipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VexProgressBarComponent } from '@vex/components/vex-progress-bar/vex-progress-bar.component';
import { LoaderService } from 'src/app/service/loader/loader.service';
import { PageLoaderComponent } from '../components/page-loader/page-loader.component';

@Component({
  selector: 'empty-layout',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet, VexProgressBarComponent, PageLoaderComponent]
})
export class EmptyLayoutComponent implements OnDestroy {
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {}
}
