<app-loader [loading]="isLoading"></app-loader>

<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ title }}
  </h2>

  <button
    class="text-secondary"
    mat-dialog-close
    mat-icon-button
    type="button"
    aria-label="close">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<ngx-extended-pdf-viewer
  [src]="src"
  [height]="'80vh'"
  (pageRendered)="onPageRendered($event)"
  [(formData)]="formData"
  (pdfLoaded)="onLoadComplete($event)"
  (error)="onPdfLoadError($event)"
  [showOpenFileButton]="showOpenFileButton">
</ngx-extended-pdf-viewer>
<!-- <input type="text" id="signature" #signatureInput /> -->
<!-- <mat-dialog-content> -->
<!-- <div class="overflow-y-auto pdf-container">
    <div [innerHTML]="pdfHtml"></div>
  </div> -->
<!-- </mat-dialog-content> -->
<mat-dialog-actions class="flex flex-row justify-end">
  <!-- <button mat-stroked-button class="cancel" mat-dialog-close type="button">
    Cancel
  </button> -->
  <button
    mat-flat-button
    type="button"
    color="primary"
    *ngIf="showOnSubmit"
    [disabled]="isProcessing"
    (click)="onSubmit()"
    aria-label="button">
    {{ submitButtonLabel }}
  </button>
</mat-dialog-actions>
