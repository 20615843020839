import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild
} from '@angular/core';
import { SharedModule } from 'src/app/core/shared/shared.module';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService
} from 'src/app/pdf/ngx-extended-pdf-viewer';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/layouts/components/loader/loader.component';
import { firstValueFrom } from 'rxjs';
import { ProgramConfigService } from '../../program-config.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vex-view-pdf',
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
    LoaderComponent,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    NgIf,
    MatButtonModule
  ],
  providers: [NgxExtendedPdfViewerService],
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent {
  isLoading: boolean = true;
  src: any;
  title: string = 'View Form';
  pdfData: any;
  disableFiled: any;
  showOnSubmit: boolean = false;
  submitButtonLabel: string = 'Process Request';
  @ViewChild(NgxExtendedPdfViewerComponent, { static: false })
  private pdfViewer!: NgxExtendedPdfViewerComponent;
  isProcessing: boolean = false;
  showOpenFileButton: boolean = false;
  constructor(
    private pdfService: NgxExtendedPdfViewerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewPdfComponent>,
    private _elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private programConfigService: ProgramConfigService,
    private appSettingsService: AppSettingsService,
    private toastr: ToastrService
  ) {
    this.title = data?.title;
    this.pdfData = data?.loadPdfData;
    this.disableFiled = data?.disableFiled;
    dialogRef.beforeClosed().subscribe((result) => {
      this.pdfViewer.ngOnDestroy();
    });
    this.showOnSubmit = data?.loadPdfData?.showOnSubmit;
    this.submitButtonLabel = data?.submitButtonLabel
      ? data?.submitButtonLabel
      : 'Process Request';
    this.showOnSubmit = data?.loadPdfData?.showOnSubmit
      ? data?.loadPdfData?.showOnSubmit
      : data?.showOnSubmit
      ? data?.showOnSubmit
      : false;
  }

  public formData: any = {};
  pdfHtml: any;
  async ngOnInit() {
    this.onUploadFormData();

    if (this.data?.loadPdfData) {
      this.src = await this.updatePdfFormFields(this.data.src);
    } else {
      this.src = this.data?.src;
    }
  }
  onUploadFormData() {
    if (this.data && this.data?.loadPdfData) {
      this.formData = {
        ...this.pdfData,
        ZipCode: this.pdfData?.CoPostalCode
      };
    }
  }

  onPageRendered(ev?: any) {
    setTimeout(async () => {
      // if (this.data && this.data?.loadPdfData) {
      // this.formData = {
      //   ...this.pdfData,
      //   ZipCode: this.pdfData?.CoPostalCode
      // };
      // Loop through each key in pdfData and update the corresponding form element

      const LicenseFeeInput = this._elementRef.nativeElement.querySelector(
        'input[name="LicenseFee"], select[name="LicenseFee"], textarea[name="LicenseFee"]'
      );
      const LicenseRenewalFeeInput =
        this._elementRef.nativeElement.querySelector(
          'input[name="LicenseRenewalFee"], select[name="LicenseRenewalFee"], textarea[name="LicenseRenewalFee"]'
        );
      const SampleFeeInput = this._elementRef.nativeElement.querySelector(
        'input[name="SampleFee"], select[name="SampleFee"], textarea[name="SampleFee"]'
      );

      [LicenseFeeInput, LicenseRenewalFeeInput, SampleFeeInput].forEach(
        (input) => {
          if (input) {
            input.disabled = true;
            input.readOnly = true;
            input.style.fontFamily = "'ArialBold'";
            input.style.fontWeight = 'bold';
          }
        }
      );
      const inputElements = this._elementRef.nativeElement.querySelectorAll(
        'input[name], select[name], textarea[name]'
      );
      inputElements.forEach(
        (
          inputElement:
            | HTMLInputElement
            | HTMLSelectElement
            | HTMLTextAreaElement
        ) => {
          inputElement.style.fontFamily = 'arial';
          inputElement.style.padding = '0 5px';
          // inputElement.style.fontSize = '18px';
          // inputElement.disabled = true;

          if (
            inputElement.name &&
            inputElement.name.toLowerCase().includes('date')
          ) {
            const isReadOnlyOrDisabled =
              inputElement.disabled ||
              (inputElement instanceof HTMLInputElement &&
                inputElement.readOnly);
            inputElement.setAttribute(
              'placeholder',
              isReadOnlyOrDisabled ? '' : 'YYYY Month DD'
            );
          }

          if (
            inputElement.name &&
            (inputElement.name.toLowerCase().endsWith('sign') ||
              inputElement.name.toLowerCase().endsWith('signature') ||
              inputElement.name.toLowerCase().includes('initial'))
          ) {
            const isReadOnlyOrDisabled =
              inputElement.disabled ||
              (inputElement instanceof HTMLInputElement &&
                inputElement.readOnly);
            inputElement.style.cursor = isReadOnlyOrDisabled
              ? 'not-allowed'
              : 'url(/assets/img/icons/heroIcon/Edit-pen.png) 10 10, auto';
            inputElement.style.fontFamily = 'new-cursive';
          }
        }
      );

      this.cdr.detectChanges();
      // }

      if (this.disableFiled) {
        // Loop through each key in pdfData and update the corresponding form element
        const inputElements = this._elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );

        // Loop through each element and disable it
        inputElements.forEach(
          (
            inputElement:
              | HTMLInputElement
              | HTMLSelectElement
              | HTMLTextAreaElement
          ) => {
            inputElement.disabled = true;
          }
        );

        this.setReadonlyFieldsContinuously();
      }

      this.onInputFieldToolTip();
      if (
        this.submitButtonLabel == 'Request Renewal' ||
        this.submitButtonLabel == 'Request Termination'
      ) {
        this.disableIFC07FormInputs();
      }
      this.cdr.detectChanges();
    }, 100);
  }
  signatureKeys = ['CQA02Signature', 'CQA03Signature'];
  // signature = this.signatureKeys.map((i:any)=>{
  //   return i
  // })
  @ViewChild('signatureInput') signatureInput: ElementRef;
  ngAfterViewInit() {
    // this.enableSignatureClick();
    this.onPageRendered();
  }
  enableSignatureClick() {
    this.signatureInput.nativeElement.addEventListener('click', () => {
      const editorButton = document.getElementById('primaryEditorInk');
      if (editorButton) {
        editorButton.click();
      }
    });
  }

  setReadonlyFieldsContinuously() {
    const observer = new MutationObserver(() => {
      const LicenseFeeInput = this._elementRef.nativeElement.querySelector(
        `input[name="LicenseFee"], select[name="LicenseFee"], textarea[name="LicenseFee"]`
      );
      const LicenseRenewalFeeInput =
        this._elementRef.nativeElement.querySelector(
          `input[name="LicenseRenewalFee"], select[name="LicenseRenewalFee"], textarea[name="LicenseRenewalFee"]`
        );

      if (LicenseFeeInput) {
        LicenseFeeInput.readOnly = true;
      }
      if (LicenseRenewalFeeInput) {
        LicenseRenewalFeeInput.readOnly = true;
      }

      // Trigger change detection to ensure the view is updated
      this.cdr.detectChanges();
    });

    // Observe changes in the child elements of the PDF container
    observer.observe(this._elementRef.nativeElement, {
      childList: true,
      subtree: true
    });
  }

  public blob: Blob | undefined;
  agreementFileName: string;
  public async export(): Promise<void> {
    this.isLoading = true;
    this.isProcessing = true;
    this.blob = await this.pdfService.getCurrentDocumentAsBlob();
    const fileName = `${this.title}.pdf`;
    const formData = new FormData();
    formData.append('file', this.blob, fileName);

    let res = await firstValueFrom(
      this.programConfigService.postAttachment(formData)
    );
    this.agreementFileName = res.fileName;
  }

  async onSubmit() {
    await this.export();
    this.isProcessing = false;
    this.isLoading = false;
    this.dialogRef.close({
      submit: true,
      agreementFileName: this.agreementFileName // Pass the file name
    });
  }

  public async updatePdfFormFields(pdfUrl: string): Promise<void> {
    try {
      if (this.data && this.data?.loadPdfData) {
        this.formData = {
          ...this.pdfData
        };
      }
      let data = await this.appSettingsService.updatePdfFormFields(
        pdfUrl,
        this.formData
      );
      return data;
    } catch (error) {
      console.error('Error updating PDF form fields:', error);
    } finally {
      this.isLoading = false;
      this.isProcessing = false;
      this.cdr.detectChanges();
    }
  }

  onLoadComplete(ev: any) {
    this.isLoading = false;
  }

  onPdfLoadError(error: any): void {
    console.error('Error loading PDF:', error);

    // Show error message to the user
    this.toastr.error('Failed to load the PDF. Please try again.');
  }

  //end of the input field tooltip
  onInputFieldToolTip() {
    this._elementRef.nativeElement
      .querySelectorAll('.textWidgetAnnotation input')
      .forEach((input: any) => {
        // Check if an element's content is overflowing horizontally
        // const isOverflowing = (element: any) =>
        //   element.scrollWidth > element.clientWidth &&
        //   element.scrollHeight <= element.clientHeight &&
        //   !element.hasAttribute('disabled') && // Not disabled
        //   !element.hasAttribute('readonly'); // Not readonly
        const zoomLevel = window.devicePixelRatio || 1;
        const isOverflowing = (element: HTMLElement) => {
          const tolerance = 2 / zoomLevel; // Adjust tolerance based on zoom level
          return (
            element.scrollWidth > element.clientWidth + tolerance &&
            element.scrollHeight <= element.clientHeight &&
            !element.hasAttribute('disabled') && // Not disabled
            !element.hasAttribute('readonly') // Not readonly
          );
        };

        // Create and show a tooltip
        const showTooltip = (element: any) => {
          // If a tooltip already exists, remove it
          if (element._tooltip) {
            removeTooltip(element);
          }

          const tooltip = document.createElement('div');
          tooltip.className = 'pdf-tooltip';
          tooltip.textContent = element.value;

          // Tooltip styling
          tooltip.style.position = 'absolute';
          tooltip.style.backgroundColor = '#333';
          tooltip.style.color = '#fff';
          tooltip.style.padding = '5px 10px';
          tooltip.style.borderRadius = '4px';
          tooltip.style.fontSize = '12px';
          tooltip.style.whiteSpace = 'normal'; // Allows wrapping of content
          tooltip.style.zIndex = '1000';

          // Enable the tooltip to dynamically increase its height as needed
          tooltip.style.display = 'block'; // Ensures the tooltip grows based on its content
          tooltip.style.maxWidth = '200px'; // Limit the width of the tooltip
          tooltip.style.width = 'auto'; // Set to auto to expand based on content
          tooltip.style.wordWrap = 'break-word'; // Break words if necessary to prevent overflow

          // // Tooltip position
          // tooltip.style.top = `${
          //   element.getBoundingClientRect().top - 30 + window.scrollY
          // }px`;
          // tooltip.style.left = `${
          //   element.getBoundingClientRect().left + window.scrollX
          // }px`;

          document.body.appendChild(tooltip);
          // Calculate the tooltip's height after it has been added to the DOM
          const tooltipHeight = tooltip.offsetHeight;

          // Calculate the position based on the content size
          const elementRect = element.getBoundingClientRect();
          const tooltipTop =
            elementRect.top - tooltipHeight - 5 + window.scrollY; // Adjust 5px for spacing

          // Tooltip position based on the element
          tooltip.style.top = `${tooltipTop}px`;
          tooltip.style.left = `${elementRect.left + window.scrollX}px`;
          element._tooltip = tooltip; // Store reference to tooltip
        };

        // Remove the tooltip
        const removeTooltip = (element: any) => {
          if (element._tooltip) {
            element._tooltip.remove(); // Remove the tooltip from DOM
            element._tooltip = null; // Clear the reference
          }
        };

        // Add or remove the icon based on overflow
        const updateIcon = () => {
          const value = input.value?.trim();
          const existingIcon =
            input.parentElement?.querySelector('.tooltip-icon');
          if (isOverflowing(input) && value) {
            if (!existingIcon) {
              // Add an icon inside the input field
              const icon = document.createElement('span');
              icon.className = 'tooltip-icon';
              icon.innerHTML = '&#9432;'; // Info icon (ℹ)
              icon.style.position = 'absolute';
              icon.style.right = '0px'; // Position near the right edge
              icon.style.top = '50%';
              icon.style.transform = 'translateY(-50%)'; // Center vertically
              icon.style.cursor = 'pointer !important';
              icon.style.color = '#333';
              icon.style.zIndex = '10';

              // Dynamically set the font size based on input field height
              const inputHeight = input.offsetHeight; // Get the input field's height
              icon.style.fontSize = `${inputHeight * 0.6}px`; // Set font size to 60% of input height
              // Ensure the parent container is relatively positioned
              const parent = input.parentElement;

              // Adjust the input field's padding to avoid overlap
              input.style.paddingRight = `${inputHeight * 0.6}px`; // Apply the padding

              // Append the icon to the parent (overlapping the input field)
              parent?.appendChild(icon);

              // Add event listeners for the icon
              icon.addEventListener('mouseenter', () => {
                if (isOverflowing(input)) {
                  if (value && input) {
                    showTooltip(input);
                  }
                }
              });
              icon.addEventListener('mouseleave', () => {
                removeTooltip(input);
              });
            } else {
              // Dynamically set the font size based on input field height
              const inputHeight = input.offsetHeight; // Get the input field's height
              const inputWidth = input.offsetWidth; // Get the input field's width
              existingIcon.style.fontSize = `${inputHeight * 0.6}px`; // Set font size to 60% of input height
              // Adjust the input field's padding to avoid overlap
              input.style.paddingRight = `${inputHeight * 0.6}px`; // Apply the padding
            }

            let debounceTimeout: any;
            input.addEventListener('input', () => {
              clearTimeout(debounceTimeout);
              debounceTimeout = setTimeout(() => {
                if (isOverflowing(input)) {
                  if (value && input) {
                    showTooltip(input);
                  }
                }
              }, 300); // Adjust debounce time as needed
            });
            // Add blur event listener to remove the icon when input loses focus
            input.addEventListener('blur', () => {
              removeTooltip(input);
            });
          } else {
            // Remove the icon if it exists
            if (existingIcon) {
              existingIcon.remove();
            }
          }
        };

        // Initial check for overflow
        updateIcon();

        // Add input event listener to dynamically update the icon
        // input.addEventListener('input', updateIcon);

        let debounceTimeout: any;
        input.addEventListener('input', () => {
          clearTimeout(debounceTimeout);
          debounceTimeout = setTimeout(() => {
            updateIcon();
          }, 50); // Adjust debounce time as needed
        });

        // Add resize event listener to handle layout changes
        // window.addEventListener('resize', updateIcon);
      });
  }

  disableIFC07FormInputs() {
    const observer = new MutationObserver(() => {
      const keys = ['CQA07Signature', 'CQA07SignName', 'CQA07SignDate'];

      // Loop through the keys and disable inputs based on the key names
      keys.forEach((key) => {
        const inputElement = this._elementRef.nativeElement.querySelector(
          `input[name="${key}"], select[name="${key}"], textarea[name="${key}"]`
        );

        if (inputElement) {
          inputElement.disabled = true;
          inputElement.readonly = true;
          inputElement.hidden = true;
        }
      });

      // Trigger change detection to ensure the view is updated
      this.cdr.detectChanges();
    });

    // Observe changes in the child elements of the component container
    observer.observe(this._elementRef.nativeElement, {
      childList: true,
      subtree: true
    });

    const keys = ['CQA07Signature', 'CQA07SignName', 'CQA07SignDate'];

    // Loop through the keys and disable inputs based on the key names
    keys.forEach((key) => {
      const inputElement = this._elementRef.nativeElement.querySelector(
        `input[name="${key}"], select[name="${key}"], textarea[name="${key}"]`
      );

      if (inputElement) {
        inputElement.disabled = true;
        inputElement.readonly = true;
        inputElement.hidden = true;
      }
    });
  }
}
