import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { LoaderService } from 'src/app/service/loader/loader.service';

@Injectable({ providedIn: 'root' })
export class GlobalComponentTrackerService {
  private activeComponentCount = 0;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private loaderService: LoaderService
  ) {
    const originalAttachView = appRef.attachView.bind(appRef);
    const originalDetachView = appRef.detachView.bind(appRef);

    // Intercept component creation
    appRef.attachView = (viewRef) => {
      this.activeComponentCount++;
      originalAttachView(viewRef);
    };

    // Intercept component destruction
    appRef.detachView = (viewRef) => {
      this.activeComponentCount = Math.max(0, this.activeComponentCount - 1);
      if (this.activeComponentCount === 0) {
        this.loaderService.hideLoader(false);
      }
      originalDetachView(viewRef);
    };
  }
}
