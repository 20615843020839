import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { GlobalComponentTrackerService } from './global-component-tracker.service';
export function initTracker(tracker: GlobalComponentTrackerService) {
  return () => {
    /* No-op, just to initialize it */
  };
}
export function provideGlobalComponentTracker(): Array<
  Provider | EnvironmentProviders
> {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initTracker,
      deps: [GlobalComponentTrackerService],
      multi: true
    }
  ];
}
