import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { LoaderService } from 'src/app/service/loader/loader.service';

@Component({
  selector: 'vex-page-loader',
  standalone: true,
  imports: [NgIf, AsyncPipe],
  template: `
    <div *ngIf="isLoading$ | async" class="loader-overlay relative">
      <div id="loader" class="text-center">
        <div class="loader mx-auto mb-4">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p class="text-white text-base">One moment please...</p>
      </div>
    </div>
  `,
  styles: [
    `
      .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* semi-transparent black */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1200;
        /* Ensure it's above other content */
      }
    `
  ]
})
export class PageLoaderComponent {
  private loaderService = inject(LoaderService);
  isLoading$ = this.loaderService.isLoading$;
}
