<!-- Loading bar -->
<vex-progress-bar></vex-progress-bar>

<!-- Wrapper -->

<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
<vex-page-loader></vex-page-loader>

<router-outlet *ngIf="true"></router-outlet>
