import { LoaderService } from 'src/app/service/loader/loader.service';
import { SwalDialogService } from 'src/app/core/shared/services/swal-dialog.service';
import { isNil } from '@vex/utils/is-nil';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
  signal
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { firstValueFrom } from 'rxjs';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CartManager } from './cart-manager';
import { CartService } from './cart.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authentication.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AddPoNumberComponent } from 'src/app/pages/quotation/add-po-number/add-po-number.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuotationService } from 'src/app/pages/quotation/quotation.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppLoaderComponent } from '../loader/app-loader.component';

@Component({
  selector: 'vex-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    MatListModule,
    MatRippleModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    CartItemsComponent,
    SharedModule,
    MatTooltipModule,
    AppLoaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe]
})
export class cartComponent implements OnInit {
  cartData: any = { cartItems: [], quotationDetails: [] };
  subTotal: number;
  processingFee: number;
  taxes: number;
  total: number;

  userInfo: any;
  programConfigId: any;
  programDetails: any;
  totalPrice: any;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  isLoading = signal<boolean>(false);
  licenseAgreementCart: boolean = false;
  licenseAgreementCartTotalPriceDue: number;

  sampleDueItemsCartItem: boolean = false;
  SampleDuePaymentMethod: string = '';
  constructor(
    private router: Router,
    private readonly layoutService: VexLayoutService,
    private cartManager: CartManager,
    private cartService: CartService,
    private appSettingsService: AppSettingsService,
    public userCheck: UserAuthenticationService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private dialog: MatDialog,
    private quotationService: QuotationService,
    private currencyPipe: CurrencyPipe,
    private swalDialogService: SwalDialogService,
    private loaderService: LoaderService
  ) {}

  async ngOnInit() {
    this.userInfo = this.appSettingsService.userData().userDetails;
    await this.onInit();
  }

  async onInit() {
    try {
      let programInfo = await firstValueFrom(this.dataService.getUserData());
      this.dataService
        .getProgramCartObservable()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async (res) => {
          if (res) {
            this.dataService.triggerProgramCartRefresh(false);
            let programInfo = await firstValueFrom(
              this.dataService.getUserData()
            );
            this.licenseAgreementCart = false;
            if (programInfo && programInfo?.programConfigId !== null) {
              await this.getCartData(programInfo, programInfo?.programConfigId);
              this.cdr.detectChanges();
            }
          }
        });
      if (programInfo?.programConfigId) {
        this.programConfigId = programInfo?.programConfigId;
      }
    } catch (err) {
      console.error(err);
    }
  }

  get cartDataGetter() {
    return this.cartData;
  }

  async getCartData(programInfo: any, programConfigId: number) {
    console.log('programInfo', programInfo);
    let params: any = {
      userID: this.userInfo.id
    };
    if (!programConfigId) return;
    this.programDetails = programInfo;
    let agreement: any;

    let data = {
      AgreementItem: []
    };
    this.cartData = data;
    // }
    try {
      this.isLoading.set(true);
      let cartResp = await firstValueFrom(
        this.cartService.getCart(this.userInfo?.company?.id, programConfigId)
      );
      const cartId = parseInt(cartResp?.id);
      this.totalPrice = 0;

      cartResp.cartItems.forEach((item: any) => {
        if (
          !item.quotationId &&
          item.class === 'sample' &&
          item.type != 'SampleDue'
        ) {
          this.totalPrice += item.price;
        }

        if (
          [
            'AnnualRenewalLicenseAgreement',
            'LicenseAgreement',
            'LicenseAgreementDue',
            'LASubscriptionPayment'
          ].includes(item.class)
        ) {
          this.licenseAgreementCart = true;
          const isDueAgreement = item.class == 'LicenseAgreementDue';
          const isAnnualRenewal =
            item.class == 'AnnualRenewalLicenseAgreement' ||
            !isNil(item.annualRenewalNoticeId);
          let unPaidMonth = item?.unPaidMonth ? parseInt(item.unPaidMonth) : 1;
          let fee = isAnnualRenewal
            ? parseInt(programInfo.renewalFee)
            : parseInt(programInfo.licenseFee);
          let annualFee = isAnnualRenewal
            ? parseInt(programInfo.renewalFee)
            : parseInt(programInfo.licenseFee);
          // if (item.class == 'LASubscriptionPayment') {
          //   fee = parseInt(item.price);
          // }

          let name = `${programInfo.programName} ${
            isAnnualRenewal ? 'License Renewal' : 'License'
          }`;
          if (isDueAgreement || item.class == 'LASubscriptionPayment') {
            if (item?.installementInterval == 'month') {
              let formattedAmount = this.currencyPipe.transform(
                this.getMonthInstallment(annualFee),
                'USD',
                'symbol',
                '1.0-0'
              );
              name = `${programInfo.programName} ${
                isAnnualRenewal
                  ? `License Renewal  ${formattedAmount} x ${unPaidMonth} Month`
                  : `License ${formattedAmount} x ${unPaidMonth} Month`
              }`;

              fee = this.getMonthlyInstallment(annualFee, unPaidMonth);
            }
          }
          //Annual fee //todo:monthly fee pay
          let totalCartItemPrice: number = fee;
          totalCartItemPrice =
            item?.latePaymentFee != null
              ? fee + parseInt(item?.latePaymentFee)
              : fee;
          let data = {
            cartId: cartId,
            userID: this.userInfo.id,
            companyID: programInfo.companyId,
            programConfigID: programInfo.programConfigId,
            AgreementItem: [
              {
                class: 'LicenseAgreement',
                name: name,
                price: annualFee,
                quotationDetails: item?.quotationDetails,
                quotationId: item?.quotationDetails?.id,
                isQuotation: !!item?.quotationDetails?.id,
                annualRenewalNoticeId: isAnnualRenewal
                  ? parseInt(item.annualRenewalNoticeId)
                  : undefined,
                id: +item.id,
                cartId: cartId,
                programConfigId: programInfo.programConfigId,
                cartItemId: +item.id,
                agreementId: item?.agreementId,
                isDueAgreement: isDueAgreement,
                latePaymentFee: parseInt(item?.latePaymentFee) || 0,
                type: item.class,
                licenseAgreementCartItemTotalPriceDue: totalCartItemPrice,
                totalCartItemPrice: totalCartItemPrice,
                isAgreementCheckout: true,
                paymentMethod: item?.paymentMethod,
                installmentInterval: item?.installementInterval,
                PONumber: item?.quotationDetails?.PONumber,
                installmentMonth: parseInt(item.installmentMonth),
                isUnPaidMonth: true, //todo if we get isUnpaidMonth
                unPaidMonthCount: unPaidMonth,
                CartItemPaymentTransactionsId: item.paymentTransactionsId,
                monthlyFee: this.getMonthlyInstallment(annualFee, unPaidMonth)
              }
            ]
          };
          if (data.AgreementItem.length != 0) {
            this.setAgreementDataCheckout(data.AgreementItem[0]);
          }

          this.licenseAgreementCartTotalPriceDue = item?.quotationDetails?.id
            ? item.quotationDetails.amount
            : fee;

          this.cartData = data;
        }
      });
      cartResp.cartItems = cartResp.cartItems
        .filter((cartItems: any) => {
          if (
            cartItems.class != 'LicenseAgreement' &&
            cartItems.class != 'AnnualRenewalLicenseAgreement' &&
            cartItems.class != 'LicenseAgreementDue' &&
            cartItems.class != 'LASubscriptionPayment' &&
            cartItems.type != 'SampleDue'
          ) {
            return cartItems;
          }
        })
        .filter(Boolean);
      // if (!this.licenseAgreementCart) {
      //   this.cartService.cartItemCount = this.cartService.cartItemCount;
      // }
      this.selectedCartItems = [];
      this.cartData = {
        ...this.cartData,
        ...cartResp
      };
      this.cartManager.cartData = cartResp;
      if (this.cartData.AgreementItem.length == 0) {
        this.dataService.setAgreementDataCheckoutSignal(null);
      }
      // this.totalPrice = this.cartData?.totalPrice;

      this.dataService.triggerProgramCartCountRefresh();
      console.log('   this.cartData', this.cartData);
      this.isLoading.set(false);
      this.cdr.detectChanges(); // Trigger manual change detection
    } catch (err: any) {
      if (this.licenseAgreementCart) {
        this.cartService.cartItemCount = 1;
      } else {
        this.cartService.cartItemCount = 0;
      }
      //todo sample cart
      // console.log(this.cartData);

      this.cartData = {
        ...this.cartData,
        cartItems: [],
        quotationDetails: [],
        sampleDueCartItems: [],
        AgreementItem: []
      };
      if (this.cartData.AgreementItem.length == 0) {
        this.dataService.setAgreementDataCheckoutSignal(null);
      }
      this.dataService.triggerProgramCartCountRefresh();
      this.isLoading.set(false);
      this.cdr.detectChanges(); // Trigger manual change detection
    }
  }
  async deleteCart(item: any): Promise<void> {
    if (!item?.id) return;

    const confirmed = await this.swalDialogService.showMessage({
      title: 'Are you sure you want to delete this item from the cart?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'Cancel'
    });

    if (confirmed) {
      try {
        this.loaderService.show();

        await firstValueFrom(
          this.cartService.deleteCartItem(this.cartData.id, item.id)
        );

        this.loaderService.hide();

        await this.swalDialogService.showMessage({
          title: 'Item deleted successfully'
        });

        this.ngOnInit();
      } catch (err: any) {
        this.loaderService.hide();
        this.appSettingsService.handleError(err);
      }
    }
  }

  checkout() {
    const queryParams: any = {
      programConfigId: this.programDetails.id
    };

    // Add cartItems only if selectedCartItems has items
    if (this.selectedCartItems.length > 0) {
      queryParams.cartItems = this.selectedCartItems.map((i) => i.id).join(',');
    }

    this.dataService.setCheckoutDataSignal({
      ...queryParams
    });
    this.router.navigate(['/checkout'], {
      queryParams: queryParams
    });
    this.layoutService.closeCart();
  }

  onAgreementCheckout(agreementDetails: any) {
    this.router.navigate(['/home', 1], {
      queryParams: {
        programConfigId: this.programDetails.id
      }
    });

    this.setAgreementDataCheckout(agreementDetails);
    this.layoutService.closeCart();
  }
  cancel(): void {
    this.layoutService.closeCart();
  }

  async onClearCart(): Promise<any> {
    try {
      this.appSettingsService
        .confirm('Delete', 'Are you sure you want to delete?')
        .then(async (res) => {
          if (res) {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCart(
                this.cartData.id,
                this.programConfigId
              )
            );
            this.dataService.triggerProgramCartRefresh(true);
          }
        });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  checkoutQuot(quoatId: number): void {
    const queryParams: any = {
      programConfigId: this.programDetails.id,
      quotationNumber: quoatId
    };

    // Add cartItems only if selectedCartItems has items
    // if (this.selectedCartItems.length > 0) {
    //   queryParams.cartItems = this.selectedCartItems
    //     .map((i) => i.id)
    //     .join(',');
    // }
    this.dataService.setCheckoutDataSignal({
      programConfigId: this.programDetails.id,
      quotationNumber: quoatId
    });

    this.router.navigate(['/checkout'], {
      queryParams: queryParams
    });
    this.layoutService.closeCart();
  }
  selectedCartItems: any[] = [];

  itemChecked(ev: { item: any; isChecked: boolean }): void {
    this.totalPrice = 0;

    if (ev.isChecked) {
      // If the item is checked, push it to selectedCartItems
      this.selectedCartItems.push(ev.item);
      this.selectedCartItems.forEach((i) => {
        this.totalPrice += i.price;
      });
      this.cdr.detectChanges(); // Trigger manual change detection
    } else {
      // If the item is unchecked, remove it from selectedCartItems
      this.selectedCartItems = this.selectedCartItems.filter(
        (i) => i.id !== ev.item.id
      );
      if (this.selectedCartItems.length > 0) {
        this.selectedCartItems.forEach((i) => {
          this.totalPrice += i.price;
        });
        this.cdr.detectChanges(); // Trigger manual change detection
      } else {
        this.cartData.cartItems.forEach((item: any) => {
          if (!item.quotationId) {
            this.totalPrice += item.price;
          }
        });
        this.cdr.detectChanges(); // Trigger manual change detection
      }
    }
    this.cdr.detectChanges(); // Trigger manual change detection
  }

  onOpenPanel(cartItem: any): boolean {
    // Check if any of the items in cartItem.cartItems array exist in selectedCartItems
    const isItemSelected = cartItem.cartItems.some((cartSubItem: any) =>
      this.selectedCartItems.some(
        (selectedItem) => selectedItem.id === cartSubItem.id
      )
    );
    return isItemSelected;
  }

  onShowSelectItemsCount(): boolean {
    return this.selectedCartItems.length > 0;
  }

  hasCartItemsOrQuotationDetails(): boolean {
    return (
      (this.cartData &&
        this.cartData.cartItems &&
        this.cartData.cartItems.length > 0) ||
      (this.cartData &&
        this.cartData.quotationDetails &&
        this.cartData.quotationDetails.length > 0)
    );
  }

  hasCartItemsDetails(): boolean {
    return (
      this.cartData &&
      this.cartData.cartItems &&
      this.cartData.cartItems.length > 0
    );
  }
  hasCartItemsAgreementDetails(): boolean {
    return this.licenseAgreementCart;
  }

  hasSampleDueCartItems(): boolean {
    return (
      this.cartData &&
      this.cartData?.sampleDueDetails &&
      this.cartData?.sampleDueDetails.length != 0
    );
  }
  onIsLoading(ev: boolean): void {
    this.isLoading.set(ev);
  }

  onAddQuotationNo(ev: any): void {
    let id = ev.id;
    let dialogRef: MatDialogRef<AddPoNumberComponent>;
    dialogRef = this.dialog.open(AddPoNumberComponent, {
      data: ev,
      autoFocus: false,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe(async (resp: any) => {
      if (resp) {
        this.isLoading.set(true);
        this.cdr.detectChanges(); // Trigger manual change detection
        let data: any = {
          PONumber: resp.PONumber
        };
        try {
          await firstValueFrom(this.quotationService.updateQuotation(id, data));
          this.isLoading.set(false);

          this.swalDialogService.showMessage({
            title: 'PO number updated successfully'
          });
          this.dataService.triggerProgramCartRefresh(true);
          this.cdr.detectChanges(); // Trigger manual change detection
        } catch (err) {
          this.appSettingsService.handleError(err);
        }
      }
    });
  }

  setAgreementDataCheckout(agreementDetails: any) {
    // Set agreement data for the main `agreementDetails`
    this.dataService.setAgreementDataCheckoutSignal({
      annualRenewalNoticeId: agreementDetails.annualRenewalNoticeId,
      cartItemId: agreementDetails.id,
      cartId: agreementDetails.cartId,
      programConfigId: this.programDetails.id,
      isQuotation: agreementDetails.isQuotation,
      quotationId: agreementDetails.quotationId,
      isDueAgreement: agreementDetails.isDueAgreement,
      latePaymentFee: agreementDetails?.latePaymentFee,
      isAgreementCheckout: true,
      totalCartItemPrice: agreementDetails.totalCartItemPrice,
      type: agreementDetails.type,
      paymentMethod: agreementDetails.paymentMethod,
      installmentInterval: agreementDetails.installmentInterval,
      PONumber: agreementDetails.PONumber,
      installmentMonth: agreementDetails.installmentMonth,
      isUnPaidMonth: agreementDetails.installmentMonth,
      unPaidMonthCount: agreementDetails.unPaidMonthCount,
      CartItemPaymentTransactionsId:
        agreementDetails.CartItemPaymentTransactionsId,
      price: agreementDetails.price,
      monthlyFee: agreementDetails.monthlyFee
    });
  }

  sampleDueCheckout(sampleDueItems: any) {
    const queryParams: any = {
      programConfigId: this.programDetails.id
    };

    queryParams.cartItems = sampleDueItems.cartItems
      .map((i: any) => i.id)
      .join(',');

    this.dataService.setCheckoutDataSignal({
      ...queryParams,
      sampleDuePaymentMethod: sampleDueItems.sampleDuePaymentMethod,
      isSampleDue: true,
      sampleLateFee: sampleDueItems.sampleLateFee,
      paymentTransactionsId: sampleDueItems.paymentTransactionsId
    });
    this.router.navigate(['/checkout'], {
      queryParams: queryParams
    });
    this.layoutService.closeCart();
  }
  isCartEmpty(): boolean {
    return (
      this.cartData?.checkoutCartItems?.length === 0 || this.cartData == null
    );
  }

  expandedPanels: { [key: string]: boolean } = {}; // Track expanded panels by QuotationNumber

  togglePanel(quotationNumber: string) {
    // Defer the change until the next tick
    setTimeout(() => {
      if (this.expandedPanels[quotationNumber]) {
        this.expandedPanels[quotationNumber] = false;
      } else {
        this.expandedPanels[quotationNumber] = true;
      }

      this.cdr.detectChanges(); // Trigger manual change detection
    }, 0);
  }

  //monthly installment plan
  getMonthlyInstallment(totalPrice: number, unPaidMonthCount: number): number {
    return (totalPrice / 12) * unPaidMonthCount;
  }

  getMonthInstallment(amount: number): number {
    return amount / 12;
  }
}
