import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root' // Makes the service available throughout the app
})
export class SwalDialogService {
  /**
   * Displays a SweetAlert dialog with customizable options.
   *
   * @param options - An object containing the following properties:
   *   @param options.title - The title of the dialog.
   *   @param options.icon - The icon to display in the dialog (default: 'success').
   *   @param options.text - The message text to display in the dialog (default: '').
   *   @param options.confirmButtonText - The text for the confirm button (default: 'Ok').
   *   @param options.showCancelButton - Whether to show a cancel button (default: false).
   *   @param options.cancelButtonText - The text for the cancel button (default: 'Cancel').
   * @returns A Promise that resolves to `true` if the confirm button is clicked, otherwise `false`.
   */
  async showMessage(options: {
    title: string;
    icon?: SweetAlertIcon;
    text?: string;
    confirmButtonText?: string;
    showCancelButton?: boolean;
    cancelButtonText?: string;
  }): Promise<boolean> {
    const {
      title,
      icon = 'success',
      text = '',
      confirmButtonText = 'Ok',
      showCancelButton = false,
      cancelButtonText = 'Cancel'
    } = options;
    // Display the SweetAlert dialog with the specified options
    const result = await Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      customClass: { popup: 'custom-swal-container' },
      reverseButtons: true
    });

    return result.isConfirmed;
  }

  /**
   * Displays a SweetAlert dialog with customizable options.
   *
   * @param options - An object containing the following properties:
   *   @param options.title - The title of the dialog.
   *   @param options.contentHtml - The HTML content to display in the dialog.
   *   @param options.showCopyButton - Whether to show a copy button (default: false).
   *   @param options.copyLabel - The label for the copy button (default: 'Copy Content').
   *   @param options.copyType - The type of content to copy ('text' or 'html', default: 'text').
   *   @param options.copyContent - The content to copy when the copy button is clicked.
   *   @param options.confirmButtonText - The text for the confirm button (default: 'Ok').
   *   @param options.cancelButtonText - The text for the cancel button (default: 'Cancel').
   *   @param options.showCancelButton - Whether to show a cancel button (default: false).
   *   @param options.icon - The icon to display in the dialog (default: 'success').
   * @returns A Promise that resolves to `true` if the confirm button is clicked, otherwise `false`.
   */
  async showMessageContent(options?: {
    title?: string;
    contentHtml?: string;
    showCopyButton?: boolean;
    copyLabel?: string;
    copyType?: 'text' | 'html';
    copyContent?: string;
    copyConfirmationText?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    icon?: SweetAlertIcon;
  }): Promise<boolean> {
    const {
      title,
      contentHtml = '',
      showCancelButton = false,
      confirmButtonText = 'Ok',
      cancelButtonText = 'Cancel',
      icon = 'success',
      showCopyButton = false,
      copyLabel = 'Copy Content',
      copyType = 'text',
      copyContent = '',
      copyConfirmationText = 'Content copied to clipboard!'
    } = options || {};

    // HTML for the copy button if showCopyButton is true
    const copyButtonHtml = showCopyButton
      ? `<button id="copy-content-btn" class="inline-block  hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
     <span class="inline-flex items-center">
       <span class="ml-1">${copyLabel}</span>
     </span>
   </button>  `
      : '';

    // Content to be copied when the copy button is clicked
    const copyContents = copyContent;

    // Display the SweetAlert dialog with the specified options
    const result = await Swal.fire({
      title: title,
      html: `
        <div id="swal-content">
          ${contentHtml}
          ${copyButtonHtml}
        </div>
      `,
      customClass: {
        popup: 'custom-swal-container'
      },
      reverseButtons: true,
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText || '',
      icon: icon,
      didOpen: () => {
        if (showCopyButton) {
          const copyBtn = document.getElementById('copy-content-btn');

          // Add click event listener to the copy button
          copyBtn?.addEventListener('click', () => {
            const content = copyContents;
            navigator.clipboard.writeText(content);
            copyBtn.textContent = copyConfirmationText;
            setTimeout(() => (copyBtn.textContent = copyLabel), 1500);
          });
        }
      }
    });

    return result.isConfirmed;
  }

  /**
   * Handles error messages and displays them using SweetAlert.
   *
   * @param err - The error object containing the error message.
   */
  handleError(err: any) {
    // Check if the error message is an object
    if (err.message && typeof err.message === 'object') {
      // Convert the error message object to a string by joining its values
      const message = Object.values(err.message).join('.\n');
      // Display the error message using SweetAlert
      this.showMessage({
        title: message,
        icon: 'error'
      });
    } else if (err.message) {
      // If the error message is a string, display it using SweetAlert
      this.showMessage({
        title: err.message,
        icon: 'error'
      });
    }
  }
}
