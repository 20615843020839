import { AppSettingsService } from './../../../core/app-settings.service';
import { LabelDesignerService } from './label-designer.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FieldType,
  IAddLabelEvent,
  IColumnMap,
  ILabelField,
  ILabelItem,
  ILabelPdf,
  ILabelValueMap,
  ISetup,
  IViewSettings,
  PresetSetup,
  QRCodeErrorCorrectionLevel
} from '../label-designer.interface';
import { IPageLayout, LabelService } from '../label.service';
import { InfoWindowService } from '../info-window/info-window.service';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { LabelExcelFileComponent } from './label-excel-file/label-excel-file.component';
import { LabelDesignerTranslationsInterface } from '../translate/label-designer-translations.interface';
import { TranslateService } from '../translate/translate.service';
import { LabelMakerFacade } from './label-maker.facade';
import { FieldKeyPipe } from '../pipe/field-key.pipe';
import { MatDrawer } from '@angular/material/sidenav';
import { event } from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
// import { AppSettingsService } from 'app/core/app-settings.service';
// import { LayoutService } from 'app/layout/layout.service';
// import { LabelPrinterService } from 'app/modules/inventory/services/label.printer.service';
// import { LabelCommandService } from 'app/modules/label-builder/service/label-command.service';
import { FormControl } from '@angular/forms';
import { FQLabelBuilderService } from 'src/app/pages/master-data/label-builder/service/label-builder.service';
import { LabelPrinterService } from 'src/app/pages/master-data/label-builder/service/label.printer.service';

/**
 * Label designer window that can be used to load, edit, show preview and send the html from the labels to the host component.
 */
@Component({
  selector: 'll-label-designer',
  templateUrl: './label-designer.component.html',
  styleUrls: ['./label-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelDesignerComponent implements OnInit, OnDestroy {
  /**
   * @internal
   */
  static id = 0;

  /**
   * @internal
   */
  @ViewChild('intro', { static: true }) intro: any;

  /**
   * @internal
   */
  @ViewChild('gettingStarted', { static: true }) gettingStarted: any;

  /**
   * @internal
   */
  _active:
    | 'file'
    | 'edit'
    | 'view'
    | 'settings'
    | 'fields'
    | 'help'
    | 'close'
    | 'map' = 'file';
  /**
   * @internal
   */
  @Input() _setup: ISetup | any;
  /**
   * @internal
   */
  _data: object[] = [];
  /**
   * @internal
   */
  _selectedLabelItem: ILabelItem | any;
  /**
   * @internal
   */
  _viewSettings: IViewSettings = { magnification: 2 };
  /**
   * @internal
   */
  generateFields: ILabelField[];
  /**
   * @internal
   */
  dragging = false;
  /**
   * @internal
   */
  filename$: Observable<string>;
  /**
   * @internal
   */
  version = '3.2.0';
  /**
   * @internal
   */
  previewActive = 0;
  addToBackside = false;
  /**
   * Default domain that is used on generate data dialog.
   */
  @Input() defaultDomain = '';
  /**
   * Setup that is used when the user clicks new from the file menu.
   * This is optional input and if this is missing there will not be new button visible.
   */
  @Input() defaultSetup: ISetup;
  /**
   * These are the default label fields. If the user import an excel file available fields changes and if that list is different from this
   * there will be a reset button visible on label fields tab.
   */
  @Input() defaultAvailableFields: ILabelField[];
  /**
   * These are all the available fields that the user can add the label.
   */
  @Input() availableFields: any;
  @Input() HazardAvailableFields: any = [];
  /**
   * Whether or not show the intro on startup.
   */
  @Input() showIntro = true;
  /**
   * Since the loading of the pdf is done externally this will tell the editor to display loading indicator on the download the pdf link.
   */
  @Input() pdfLoading = false;
  /**
   * Error correction level used on the QR Code.
   */
  @Input() qrCodeErrorCorrectionLevel: QRCodeErrorCorrectionLevel =
    QRCodeErrorCorrectionLevel.levelM;
  /**
   * Preset setups that the user can pick from the file menu.
   */
  @Input() presets: PresetSetup[];
  /**
   * Map spreadsheet columns to where fields
   */
  @Input() fileColumnMap: IColumnMap;

  /**
   * Event that triggers when the html for the label is generated. Event holds the desired filename and the html string
   * (See {@link ILabelPdf}).
   */
  @Output() html: EventEmitter<ILabelPdf> = new EventEmitter<ILabelPdf>();

  /**
   * Triggered when view settings change.
   */
  @Output() viewSettingsChange: EventEmitter<IViewSettings> =
    new EventEmitter<IViewSettings>();

  /**
   * Triggered when the data is changed. This can occurs when the excel is imported or when the data is generated using the generate dialog.
   */
  @Output() dataChange: EventEmitter<object[]> = new EventEmitter<object[]>();

  /**
   * Triggered when setup changes. This happens on every change on the label, so make sure that performance of the chain of the event's
   * that this triggers is good and that it doesn't block the ui thread.
   */
  @Output() setupChange: EventEmitter<ISetup> = new EventEmitter<ISetup>();

  /**
   * Triggered when the intro dialog is closed.
   */
  @Output() introClosed: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Triggered when available fields are changed.
   */
  @Output() availableFieldsChange: EventEmitter<ILabelField[]> =
    new EventEmitter<ILabelField[]>();

  /**
   * Triggered when pdf loading is changed
   */
  @Output() pdfLoadingChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Triggered when spreadsheet columns mapping changes
   */
  @Output() fileColumnMapChange: EventEmitter<IColumnMap> =
    new EventEmitter<IColumnMap>();

  /**
   * @internal
   */
  @ViewChild('editor', { static: false }) editor: ElementRef<HTMLDivElement>;
  /**
   * @internal
   */
  @ViewChild('generateTpl', { static: true }) generateTpl: TemplateRef<any>;
  /**
   * @internal
   */
  @ViewChild('generateActionsTpl', { static: true })
  generateActionsTpl: TemplateRef<any>;
  /**
   * @internal
   */
  @ViewChild('excelFile', { static: false })
  excelCmp: LabelExcelFileComponent;
  /**
   * @internal
   */
  @ViewChild('excelTpl', { static: true }) excelTpl: TemplateRef<any>;
  /**
   * @internal
   */
  @ViewChild('excelActionsTpl', { static: true })
  excelActionsTpl: TemplateRef<any>;

  /**
   * @internal
   */
  subIntro: Subscription;

  /**
   * @internal
   */
  generate: {
    uri: string;
    rangeStart: number | undefined;
    rangeEnd: number | undefined;
    data: { [key: string]: string };
  } = {
    uri: '',
    rangeStart: undefined,
    rangeEnd: undefined,
    data: {}
  };

  /**
   * @internal
   */
  dimensions: IPageLayout;

  private _undo: ISetup[] | any = [];
  private _redo: ISetup[] | any = [];
  @ViewChild('drawer') drawer: MatDrawer;

  drawerMode: 'over' | 'side' = 'side';
  //   drawerMode = 'push';
  drawerOpened: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showSettingPage: boolean = false;
  fieldEdit: boolean;
  labelDesignerId: number;
  label: any;
  dispId: any;
  userRole: any;
  userInfo: any;
  path: string;
  unsubscribeAll: Subscription;
  itemPosition: number = 0;
  sampleLabelCommand: string;
  commandControl = new FormControl('');
  hardWereDetails: any = {};
  menuData: any[] = [];
  /**
   * @internal
   */
  constructor(
    private labelService: LabelService,
    private renderer2: Renderer2,
    private infoWindowService: InfoWindowService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private labelMakerFacade: LabelMakerFacade,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private toastr: ToastrService,
    public appSettingsService: AppSettingsService,
    // private layoutService: LayoutService,
    // private labelPrinterService: LabelPrinterService,
    // private labelCommandService: LabelCommandService,
    private dialog: MatDialog, // private registerService: RegisterService
    private labelBuilderService: FQLabelBuilderService,
    private labelPrinterService: LabelPrinterService,
    private labelDesignerService: LabelDesignerService
  ) {
    effect(
      () => {
        const selectedItem = this.labelDesignerService.getSelectedItem();
        if (selectedItem) {
          this.onItemClick(selectedItem);
        }

        const actionType: string | undefined =
          this.labelDesignerService.getActionLabelDesigner();
        this.onCallActions(actionType);

        const menuItems = this.labelDesignerService.getMenuItems();
        if (menuItems) {
          this.availableFields = menuItems.availableFields;
          this.HazardAvailableFields = menuItems.HazardAvailableFields;
          this._updateMenuData();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    const valueToPass = { view: true, content: 'Back to label' };
    // this.layoutService.setDisplayValue(valueToPass);
    if (this.showIntro) {
      this.openIntro();
    }
    this.filename$ = this.labelMakerFacade.currentFile$;

    this.labelDesignerId = this.route.snapshot.params['id'];

    // Subscribe to media changes
    // this._fuseMediaWatcherService.onMediaChange$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(({ matchingAliases }) => {
    //     // Set the drawerMode and drawerOpened if the given breakpoint is active
    //     if (matchingAliases.includes('md')) {
    //       this.drawerMode = 'side';
    //       this.drawerOpened = true;
    //     } else {
    //       this.drawerMode = 'over';
    //       this.drawerOpened = false;
    //     }
    //   });
    this.label = history.state;
    setTimeout(() => {
      this._updateMenuData();
      this.dimensions = this.labelService.countLabelsPerPage(this._setup);
      this.cdr.detectChanges();
    }, 0);
  }

  async fetchRegisterId() {
    let registerId = this.userInfo?.register_id ?? '';

    // if (registerId === '') {
    //   // Wait for the first API to complete
    //   await new Promise<void>((resolve) => {
    //     this.registerService
    //       .getRegister(this.dispId, {})
    //       .subscribe((resp: any) => {
    //         if (resp?.items?.length > 0) {
    //           const vaultItem = resp.items.find(
    //             (item: { is_vault: any }) => item.is_vault
    //           );
    //           if (vaultItem) {
    //             registerId = vaultItem.id;
    //           }
    //         }
    //         resolve(); // Resolve after the first API call completes
    //       });
    //   });
    // }

    // Now call the second API if registerId is set
    if (registerId !== '') {
      //   this.registerService
      //     .getRegisterById(registerId) // Use the updated registerId here
      //     .subscribe(async (resp: any) => {
      //       this.hardWereDetails = resp ?? {};
      //     });
    }
  }

  ngOnDestroy(): void {
    if (this.subIntro) {
      this.subIntro.unsubscribe();
    }

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Translations for the component. See {@link LabelDesignerTranslationsInterface} to see what values can be translated.
   */
  @Input()
  set translations(translations: LabelDesignerTranslationsInterface) {
    this.translateService.setTranslations(translations);
  }

  /**
   * Array of data objects. Each object in the array will generate one label.
   */
  @Input()
  set data(data: object[]) {
    if (!Array.isArray(data)) {
      data = [];
    }
    this._data = data;
    this.setPreviewActive(0);
  }

  get data() {
    return this._data;
  }

  /**
   * View settings to use. See {@link IViewSettings} for details on what can be set.
   */
  @Input()
  set viewSettings(settings: IViewSettings) {
    if (!settings) {
      return;
    }
    if (
      isPlatformBrowser(this.platformId) &&
      settings.fullscreen !== this._viewSettings.fullscreen
    ) {
      try {
        if (settings.fullscreen) {
          const elem: any = this.editor.nativeElement;
          const enterMethod =
            elem.requestFullScreen ||
            elem.webkitRequestFullScreen ||
            elem.mozRequestFullScreen ||
            elem.msRequestFullScreen;
          if (enterMethod) {
            enterMethod.call(elem);
          }
        } else if (this._viewSettings.fullscreen) {
          const doc: any = document;
          const exitMethod =
            doc.exitFullscreen ||
            doc.webkitExitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.msExitFullscreen;
          if (exitMethod) {
            exitMethod.call(doc);
          }
        }
      } catch (e) {}
    }
    this._viewSettings = settings;
  }

  get viewSettings() {
    return this._viewSettings;
  }

  /**
   * Setup for the whole page see {@link ISetup}
   */
  @Input()
  // set setup(setup: ISetup) {
  //     if (!setup) {
  //         return;
  //     }
  //     const hasField = {};
  //     const allFields = [];
  //     LabelDesignerComponent.id = this.findTheHighestId(setup) + 1;

  //     const checkItem = (item) => {
  //         item.fields.forEach((field) => {
  //             const isText = field.type === 'text';
  //             const fieldId = FieldKeyPipe.getKey(field);
  //             if (!hasField[fieldId] && (!field.type || isText)) {
  //                 hasField[fieldId] = true;
  //                 allFields.push(field);
  //             }
  //         });
  //         return { ...item, _id: item._id || LabelDesignerComponent.id++ };
  //     };

  //     this._setup = {
  //         ...setup,
  //         labelItems: setup.labelItems.map(checkItem),
  //         backSideLabelItems: (setup.backSideLabelItems || []).map(checkItem),
  //     };
  //     this.dimensions = this.labelService.countLabelsPerPage(this._setup);
  //     this.generateFields = allFields;
  //     if (this._selectedLabelItem) {
  //         let idx = this._setup.labelItems.findIndex(
  //             (i) => i._id === this._selectedLabelItem._id
  //         );
  //         if (idx !== -1) {
  //             this._selectedLabelItem = this._setup.labelItems[idx];
  //         } else {
  //             idx = this._setup.backSideLabelItems.findIndex(
  //                 (i) => i._id === this._selectedLabelItem._id
  //             );
  //             this._selectedLabelItem = this._setup.backSideLabelItems[idx];
  //         }
  //     }
  //     this.setPreviewActive(this.previewActive);
  // }
  set setup(setup: ISetup) {
    if (!setup) {
      return;
    }

    const hasField = new Set(); // To track unique field-label combinations
    const allFields: ILabelField[] = [];
    LabelDesignerComponent.id = this.findTheHighestId(setup) + 1;

    // const checkItem = (item) => {
    //
    //     item.fields = item.fields.filter((field?: any) => {
    //         const key = `${field?.field}-${field?.label}`;

    //         if (!hasField.has(key)) {
    //             hasField.add(key); // Add the unique combination to the set
    //             allFields.push(field); // Add field to the global array if not a duplicate
    //             return true; // Keep the field
    //         }
    //         return false; // Remove duplicate field
    //     });
    //     return { ...item, _id: item._id || LabelDesignerComponent.id++ };
    // };

    const checkItem = (item: { _id: any }) => {
      // Process item and return it without removing duplicates
      return { ...item, _id: item._id || LabelDesignerComponent.id++ };
    };

    // this._setup = {
    //   ...setup,
    //   labelItems: setup.labelItems.map(checkItem),
    //   backSideLabelItems: (setup.backSideLabelItems || []).map(checkItem)
    // };
    if (this.setup.labelItems.length == 0) {
      this.itemPosition = 0;
    }

    this.generateFields = allFields;
    if (this._selectedLabelItem) {
      let idx = this._setup.labelItems.findIndex(
        (i: any) => i._id == this._selectedLabelItem._id
      );
      if (idx !== -1) {
        this._selectedLabelItem = this._setup.labelItems[idx];
      } else {
        idx = this._setup.backSideLabelItems.findIndex(
          (i: any) => i._id == this._selectedLabelItem._id
        );
        this._selectedLabelItem = this._setup.backSideLabelItems[idx];
      }
    }
    this.setPreviewActive(this.previewActive);
  }
  get setup(): ISetup | any {
    return this._setup;
  }
  /**
   * @internal
   */
  showSettings(item: ILabelItem) {
    this.showSettingPage = true;
    this.fieldEdit = this.showSettingPage;
    this.setActiveLabelItem(item);
    this._active = 'settings';
    this.cdr.detectChanges();
  }

  /**
   * @internal
   */
  setActiveLabelItem(item: ILabelItem) {
    this._selectedLabelItem = item;
    this.cdr.detectChanges();
  }

  /**
   * @internal
   */
  setupChanged(setup: ISetup, addToUndo = true) {
    if (addToUndo) {
      this._redo = [];
      this._undo.push(this._setup);
    }
    this._setup = setup;
    this.dimensions = this.labelService.countLabelsPerPage(this._setup);
    this.cdr.detectChanges();

    this.setupChange.emit(this._setup);
    if (this._undo.length > 20) {
      this._undo.shift();
    }
  }

  onSetupChanged(setup: ISetup, addToUndo = true) {
    if (addToUndo) {
      this._redo = [];
      this._undo.push(this._setup);
    }
    this._setup = setup;
    this.dimensions = this.labelService.countLabelsPerPage(this._setup);
    this.cdr.detectChanges();
    // this.setupChange.emit(this._setup);
    if (this._undo.length > 20) {
      this._undo.shift();
    }
  }
  /**
   * @internal
   */
  addLabelItem(event: any) {
    const item = event.item;
    if (!item._id) {
      item._id = LabelDesignerComponent.id++;
    }
    this._undo.push(this._setup);
    this._setup = {
      ...this._setup,
      [event.location]: [...this._setup[event.location], item]
    };
    this.labelDesignerService.selectItem(null);
    this.setupChange.emit(this._setup);
  }

  /**
   * @internal
   */
  done() {
    this._selectedLabelItem = undefined;
  }

  /**
   * Undo the changes if there are anything to undo
   */
  undo() {
    if (this.hasUndo()) {
      this._redo.push(this._setup);
      this.setupChanged(this._undo.pop(), false);
    }
  }

  /**
   * Redo the changes if there are anything to redo
   */
  redo() {
    if (this.hasRedo()) {
      this._undo.push(this._setup);
      this.setupChanged(this._redo.pop(), false);
    }
  }

  /**
   * @internal
   */
  hasUndo() {
    return this._undo.length > 0;
  }

  /**
   * @internal
   */
  hasRedo() {
    return this._redo.length > 0;
  }

  /**
   * @internal
   */
  updateGenerate(field: ILabelField | string, value: string, inData = false) {
    const key = typeof field === 'string' ? field : FieldKeyPipe.getKey(field);
    if (inData) {
      this.generate = {
        ...this.generate,
        data: {
          ...this.generate.data,
          [key]: value
        }
      };
    } else {
      this.generate = {
        ...this.generate,
        [key]: value
      };
    }
  }

  /**
   * @internal
   */
  generateData() {
    this.infoWindowService.close();
    this.generate.rangeStart = Number(this.generate.rangeStart);
    this.generate.rangeEnd = Number(this.generate.rangeEnd);
    const MAX = 10000;
    const data = [];
    const uri =
      this.generate.uri +
      (this.generate.uri.indexOf('%id%') > -1 ? '' : '%id%');
    const hasUri = uri.startsWith('http');
    const start =
      this.generate.rangeStart < this.generate.rangeEnd
        ? this.generate.rangeStart
        : this.generate.rangeEnd;
    const end =
      this.generate.rangeStart > this.generate.rangeEnd
        ? this.generate.rangeStart
        : this.generate.rangeEnd;
    const uriFieldsIdx = this.availableFields.findIndex((item: any) => {
      (item: { type: FieldType }) => item.type === FieldType.qrCode;
    });
    const uriField =
      uriFieldsIdx !== -1 ? this.availableFields[uriFieldsIdx].field : 'id';
    const domainFieldsIdx = this.availableFields.findIndex(
      (item: any) => item.type === FieldType.domain
    );
    const idFieldsIdx = this.availableFields.findIndex(
      (item: any) => item.type === FieldType.id
    );

    const addTextField = (field: ILabelField) => {
      if (field.type === FieldType.text) {
        const key = FieldKeyPipe.getKey(field);
        if (typeof this.generate.data[key] === 'undefined') {
          this.generate.data[key] = '';
        }
      }
    };

    this._setup.labelItems.forEach((item: any) => {
      item.fields.forEach(addTextField);
    });
    (this._setup.backSideLabelItems || []).forEach((item: any) => {
      item.fields.forEach(addTextField);
    });

    let current = 0;
    for (let i = start; i <= end; i++) {
      current++;
      if (current > MAX) {
        break;
      }
      const rowUri = uri.replace('%id%', '' + i);
      const rowData: any = {
        ...this.generate.data,
        [uriField]: rowUri
      };
      if (hasUri) {
        const parsedUri = LabelService.parseUri(rowUri);
        if (domainFieldsIdx > -1) {
          rowData[this.availableFields[domainFieldsIdx].field] =
            parsedUri.domain;
        }
        if (idFieldsIdx > -1) {
          rowData[this.availableFields[idFieldsIdx].field] = parsedUri.id;
        }
      }
      data.push(rowData);
    }
    this.data = data;
    this.setPreviewActive(0);
    this.dataChange.emit(this.data);
  }

  /**
   * Open getting started dialog
   */
  openGettingStarted() {
    this.subIntro = this.infoWindowService
      .open({
        title: this.translateService.get('Getting started'),
        actionTypes: 'ok',
        content: this.gettingStarted
      })
      .subscribe(() => this.introClosed.emit());
  }

  /**
   * Open intro dialog
   */
  openIntro() {
    this.subIntro = this.infoWindowService
      .open({
        title: this.translateService.get('Label Designer'),
        actionTypes: 'ok',
        content: this.intro
      })
      .subscribe(() => this.introClosed.emit());
  }
  /**
   * Open generate dialog
   */
  openGenerate() {
    if (!this.generate.uri) {
      this.generate.uri = this.defaultDomain;
    }
    LabelService.forEachField(this.setup, (field) => {
      if (field.type === FieldType.text) {
        const key = FieldKeyPipe.getKey(field);
        if (typeof this.generate.data[key] === 'undefined') {
          this.generate.data[key] = field.label;
        }
      }
    });
    this.infoWindowService.open({
      title: this.translateService.get('Generate label data'),
      content: this.generateTpl,
      actions: this.generateActionsTpl
    });
  }

  /**
   * Open excel import dialog
   */
  openImportExcel() {
    this.infoWindowService.open({
      title: this.translateService.get('Import from file'),
      content: this.excelTpl,
      actions: this.excelActionsTpl
    });
  }

  /**
   * Preview the specific item on the data array.
   */
  setPreviewActive(idx: number) {
    if (this.data && this.data[idx]) {
      this.previewActive = idx;
      this.cdr.detectChanges();
    }
  }

  /**
   * @internal
   */
  newFieldDragging(event: boolean, settings: HTMLDivElement) {
    if (event) {
      this.renderer2.setStyle(
        settings,
        'margin-top',
        '-' + settings.scrollTop + 'px'
      );
      this.renderer2.setStyle(
        settings,
        'padding-bottom',
        settings.scrollTop + 'px'
      );
      this.renderer2.setStyle(
        settings,
        'height',
        'calc(100% + ' + settings.scrollTop + 'px)'
      );
      this.renderer2.setStyle(settings, 'z-index', '-1');
    } else {
      this.renderer2.setStyle(settings, 'margin-top', '0px');
      this.renderer2.removeStyle(settings, 'z-index');
      this.renderer2.removeStyle(settings, 'padding-bottom');
      this.renderer2.setStyle(settings, 'height', '100%');
    }
    this.dragging = event;
  }

  /**
   * @internal
   */
  onViewSettingsChange(event: IViewSettings) {
    this.viewSettings = event;
    this.viewSettingsChange.emit(event);
    this.cdr.detectChanges();
  }

  /**
   * @internal
   */
  loadExcelData() {
    const result = this.excelCmp.loadData();
    if (result.availableFields) {
      this.availableFieldsChange.emit(result.availableFields);
    }
    this.data = result.data;
    this.infoWindowService.close();
    this.dataChange.emit(this.data);
  }

  clearData() {
    if (!this.data || this.data.length === 0) {
      return;
    }
    if (
      confirm(
        this.translateService.get(
          'Are you sure that you want to clear the data form the label?'
        )
      )
    ) {
      this.data = [];
      this.resetContent();
      this.dataChange.emit(this.data);
    }
  }

  /**
   * @internal
   */
  onValueMapChange(map: ILabelValueMap) {
    this.setupChanged({ ...this._setup, valueMap: map }, false);
  }

  private findTheHighestId(setup: ISetup) {
    let id = 0;
    LabelService.forEachLabelItem(setup, (item: any) => {
      if (item._id > id) {
        id = item._id;
      }
    });
    return id;
  }

  private resetContent() {
    const setup = this._setup;
    LabelService.forEachField(setup, (field) => {
      if (field.type === FieldType.text) {
        field.content = field.label;
      }
    });
    this.setupChanged(setup, false);
  }

  /**
   * @internal
   */
  onPdfLoading(loading: boolean) {
    this.pdfLoading = true;
    this.pdfLoadingChange.emit(loading);
  }
  @HostListener('click', ['$event.target']) onClick(target: any) {
    let clickedElement = target;
    clickedElement = clickedElement.parentElement;

    if (clickedElement) {
      if (clickedElement.innerText == 'Settings') {
        this.showSettingPage = this.showSettingPage == true ? false : true;
      } else if (this.fieldEdit) {
        this.showSettingPage = !this._selectedLabelItem
          ? false
          : this.fieldEdit;
        this.fieldEdit = !this._selectedLabelItem ? false : true;
      } else if (clickedElement.innerText == 'Undo') {
        this.undo();
      } else if (clickedElement.innerText == 'Redo') {
        this.redo();
      } else if (clickedElement.innerText == 'Save') {
        // this.setupChange.emit(this._setup);
        this._setup.labelItems.length > 0
          ? this.onClickFunction()
          : this.toastr.error('Please add the label.');
      } else if (clickedElement.innerText == 'Test Print') {
        // this.printLabel();
      }
    }
  }

  addField(field: ILabelField) {
    if (field) {
      console.log('field :>> ', field);
      const width = field.type === FieldType.qrCode ? 15 : 25;
      const height = field.type === FieldType.qrCode ? 14 : 5;
      this.addLabelItem({
        location:
          this.setup?.twoSided && this.addToBackside
            ? 'backSideLabelItems'
            : 'labelItems',
        item: {
          type: field.source,
          y: this.itemPosition,
          x: 0,
          fields: [field],
          style: {
            'height.mm': Math.min(height, this.setup.label['height.mm']),
            'width.mm': Math.min(width, this.setup.label['width.mm']),
            'font-size.pt': this.setup.label['font-size.pt'],
            ...field?.style
          },
          id: this.appSettingsService.generate16DigitUUID()
        }
      });
      this.itemPosition = this.itemPosition >= 20 ? 0 : this.itemPosition + 1;
    }
  }
  onBackButtonClick() {
    window.history.back();
  }

  deleteLabel(id: any) {
    this._setup.labelItems = this._setup.labelItems.filter(
      (item: any) => item._id !== id
    );
    this.cdr.detectChanges();
    this.setupChanged(this._setup);
    // this.setupChange.emit(this._setup);
  }
  closeSidebar() {
    this.showSettingPage = false;
    this._selectedLabelItem = null;
  }

  onClickFunction(): void {
    let labelName =
      '(' +
      (this.convertMmToInches(this._setup.label['height.mm']) - 0.5) +
      ' * ' +
      (this.convertMmToInches(this._setup.label['width.mm']) - 1) +
      ' inch)';

    // Create a Set to track the unique IDs
    const uniqueIds = new Set();
    // Filter out duplicates based on the 'id' field
    this._setup.labelItems = this._setup.labelItems.filter((item: any) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id); // Add the id to the Set if it's not already there
        return true; // Keep the item
      }
      return false; // Remove the duplicate
    });

    this.labelDesignerService.setLabelConfig({
      config: JSON.stringify(this._setup),
      label: this.label,
      name: labelName,
      height: this._setup.label['height.mm'],
      width: this._setup.label['width.mm']
    });
    // Open the dialog
    // const dialogRef = this._matDialog.open(SaveComponent, {
    //   data: {
    //     config: JSON.stringify(this._setup),
    //     label: this.label,
    //     dispensary_id: this.dispId,
    //     label_name: labelName
    //   }
    // });

    // dialogRef.afterClosed().subscribe((result) => {});
  }

  onPreviewPrint() {
    // Check if there are any label items to print
    if (this._setup.labelItems.length > 0) {
      // Prepare parameters for the label preview print API call
      const param = {
        name: this.label.name, // Name of the label
        config: JSON.stringify(this._setup), // Label configuration in JSON format
        dispensary_id: this.dispId // ID of the dispensary
      };
      // Call the label builder service to initiate preview print
      this.labelBuilderService.printLabelForPreview({
        data: param,
        config: this.hardWereDetails?.label_printer,
        registerId: this.hardWereDetails?.id ?? ''
      });
    } else {
      // Handle case where no label items are present
      console.warn('Preview Print: No label items available to preview.');
    }
  }
  /**
   * Converts millimeters to inches.
   * @param mm The value in millimeters to convert.
   * @returns The converted value in inches, rounded to 2 decimal places.
   */
  convertMmToInches(mm: number): number {
    // Divide the millimeter value by 25.4 (1 inch = 25.4 mm)
    // Round the result to 2 decimal places and return as a number
    return parseFloat((mm / 25.4).toFixed(2));
  }
  async onSetPaperSize(): Promise<void> {
    try {
      // Define ZPL commands for setting paper size
      const command = `^XA
^PW406
^LL203
^LS0
^MNA
~JC
^JUS
^XZ`;

      // Send the command to the label printer service
      //   this.labelPrinterService.printPreviewLabel({
      //     data: command,
      //     config: this.hardWereDetails?.label_printer,
      //     registerId: this.hardWereDetails?.id ?? ''
      //   });
    } catch (error) {
      console.error('Error setting paper size:', error);
      throw error;
    }
  }

  onTestPrint() {
    //     let dialofRef: MatDialogRef<ReasonMessageComponent>;
    //     dialofRef = this.dialog.open(ReasonMessageComponent, {
    //       data: { showReasonField: false }
    //     });
    //     dialofRef.afterClosed().subscribe((resp) => {});
  }

  private _updateMenuData(): void {
    this.menuData = [
      {
        title: 'Label',
        type: 'group',
        children: [
          {
            title: 'TEXT',
            type: 'basic',
            icon: 'heroicons_outline:tag',
            classes: { icon: 'text-green-500' },
            content: 'TEXT',
            label: 'Text',
            field_type: 'dynamic',
            field: 'text',
            id: this.appSettingsService.generate16DigitUUID(),
            source: 'field'
          }
        ]
      },
      {
        title: 'Value - Data',
        type: 'group',
        children: this.availableFields
      },
      {
        title: 'Hazard Symbol - Data',
        type: 'group',
        children: this.HazardAvailableFields
      }
    ];
    this.getLabelLoader();
    this.cdr.detectChanges();
  }
  onItemClick(event: any) {
    // Create a shallow copy of the field to avoid modifying the original object
    let modifiedField = {
      ...event,
      id: this.appSettingsService.generate16DigitUUID()
    };

    this.addField(modifiedField);
  }

  onCallActions(type: string | undefined) {
    this.labelDesignerService.setActionLabelDesigner('');
    switch (type) {
      case 'settings':
        this.showSettingPage = !this.showSettingPage;
        this.cdr.detectChanges();

      case 'redo':
        this.redo();
        break;
      case 'undo':
        this.undo();
        break;
      case 'save':
        this.onClickFunction();
        break;
    }
  }

  getLabelLoader(): boolean {
    return this.labelDesignerService.getMenuItemsLoader();
  }
}
