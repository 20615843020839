import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { firstValueFrom } from 'rxjs';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { SwalDialogService } from 'src/app/core/shared/services/swal-dialog.service';
import { CurrencyPipe, NgIf } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'vex-cart-items',
  standalone: true,
  imports: [
    NgIf,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    CurrencyPipe
  ],
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartItemsComponent {
  @Input() item: any;
  @Input() licenseAgreementCart: boolean = false;
  @Input() programDetails: any;
  @Input() showCheckBox: boolean = true;
  @Input() showDelete: boolean = true;
  @Output() itemChecked = new EventEmitter<any>(); // Emit the checked item to the parent component
  @Output() isLoading = new EventEmitter<any>();
  @Input() PONumber: string = '-';
  @Input() showPO: boolean = false;
  isChecked = false; // Track the checkbox state

  private appSettingsService = inject(AppSettingsService);
  private cartService = inject(CartService);
  private router = inject(Router);
  private layoutService = inject(VexLayoutService);
  private dataService = inject(DataService);
  private swalDialogService = inject(SwalDialogService);

  async deleteCart(item: any): Promise<void> {
    if (!item?.id) return;

    const confirmed = await this.swalDialogService.showMessage({
      title: 'Are you sure you want to delete this item from the cart?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'Cancel'
    });

    if (confirmed) {
      try {
        this.isLoading.emit(true);

        await firstValueFrom(
          this.cartService.deleteCartItem(item.cartId, item.id)
        );
        this.isLoading.emit(false);

        await this.swalDialogService.showMessage({
          title: 'Item deleted successfully'
        });

        this.dataService.triggerProgramCartRefresh(true);
      } catch (err: any) {
        this.isLoading.emit(true);
        this.appSettingsService.handleError(err);
      }
    }
  }

  checkout() {
    if (this.licenseAgreementCart) {
      if (this.programDetails.quotationId) {
        this.router.navigate(['/home', 1], {
          queryParams: {
            programConfigId: this.programDetails.id,
            quotationNumber: this.programDetails.id
          }
        });
      } else {
        this.router.navigate(['/home', 1], {
          queryParams: {
            programConfigId: this.programDetails.id
          }
        });
      }

      this.layoutService.closeCart();
    } else {
      this.router.navigate(['/checkout'], {
        queryParams: {
          programConfigId: this.programDetails.id
        }
      });
      this.layoutService.closeCart();
    }
  }

  // Function to handle checkbox changes
  onCheckChange() {
    this.itemChecked.emit({ item: this.item, isChecked: this.isChecked }); // Emit the item and whether it's checked
  }
}
