import { SwalDialogService } from 'src/app/core/shared/services/swal-dialog.service';
import { ChangeDetectorRef, Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule
} from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ToastrService } from 'ngx-toastr';
export interface Email {
  address?: string;
  cc?: string;
  bcc?: string;
}

@Component({
  selector: 'vex-multi-email-entry',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatChipsModule
  ],
  templateUrl: './multi-email-entry.component.html',
  styleUrls: ['./multi-email-entry.component.scss']
})
export class MultiEmailEntryComponent {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  toEmails: Email[] = [];
  ccEmails: Email[] = [];
  bccEmails: Email[] = [];

  announcer = inject(LiveAnnouncer);
  // Form control for email input field with validation
  toEmailFormControl = new FormControl('', [Validators.email]);
  ccEmailFormControl = new FormControl('', [Validators.email]);
  bccEmailFormControl = new FormControl('', [Validators.email]);

  toEmailFormControlError: boolean = false;
  ccEmailFormControlError: boolean = false;
  bccEmailFormControlError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MultiEmailEntryComponent>,
    private _cdr: ChangeDetectorRef,
    private swalDialogService: SwalDialogService
  ) {
    this.toEmails =
      data?.emails && data?.emails.length > 0
        ? data?.emails
            .filter((email: string) => email.trim() !== '')
            .map((email: string) => ({ address: email }))
        : [];
  }

  onSubmit() {
    this.dialogRef.close({
      to: [this.toEmails.map((email) => email.address).join(',')],
      cc: [this.ccEmails.map((email) => email.cc).join(',')],
      bcc: [this.bccEmails.map((email) => email.bcc).join(',')]
    });
  }

  add(event: MatChipInputEvent, type: string): void {
    const value = (event.value || '').trim(); // Trim the input value

    if (value == '') {
      return;
    }
    switch (type) {
      case 'to':
        if (value && this.isValidEmail(value)) {
          this.toEmails.push({ address: value });
          // Clear any existing errors if valid
          this.toEmailFormControlError = false;
          event.chipInput!.clear(); // Clear the input value only when valid
        } else {
          // Set the error if the email is invalid
          this.toEmailFormControlError = true;
          this.swalDialogService.showMessage({
            title: 'Please enter a valid email address',
            icon: 'error'
          });
        }
        this.toEmailFormControl.updateValueAndValidity();

        break;

      case 'cc':
        if (value && this.isValidEmail(value)) {
          this.ccEmails.push({ cc: value });
          // Clear any existing errors if valid
          this.ccEmailFormControlError = false;
          event.chipInput!.clear(); // Clear the input value only when valid
        } else {
          // Set the error if the email is invalid
          this.ccEmailFormControlError = true;
          this.swalDialogService.showMessage({
            title: 'Please enter a valid email address',
            icon: 'error'
          });
        }

        break;

      case 'bcc':
        if (value && this.isValidEmail(value)) {
          this.bccEmails.push({ bcc: value });
          // Clear any existing errors if valid
          this.bccEmailFormControlError = false;
          event.chipInput!.clear(); // Clear the input value only when valid
        } else {
          // Set the error if the email is invalid
          this.bccEmailFormControlError = true;
          this.swalDialogService.showMessage({
            title: 'Please enter a valid email address',
            icon: 'error'
          });
        }

        break;

      default:
        break;
    }

    this._cdr.markForCheck();
  }

  remove(email: Email, type: string): void {
    switch (type) {
      case 'to':
        const toIndex = this.toEmails.indexOf(email);

        if (toIndex >= 0) {
          this.toEmails.splice(toIndex, 1);
          this.announcer.announce(`Removed ${email.address}`);
        }

        break;
      case 'cc':
        const ccIndex = this.ccEmails.indexOf(email);

        if (ccIndex >= 0) {
          this.ccEmails.splice(ccIndex, 1);
          this.announcer.announce(`Removed ${email.cc}`);
        }

        break;
      case 'bcc':
        const bccIndex = this.bccEmails.indexOf(email);

        if (bccIndex >= 0) {
          this.bccEmails.splice(bccIndex, 1);
          this.announcer.announce(`Removed ${email.bcc}`);
        }

        break;
    }
  }

  edit(email: Email, event: MatChipEditedEvent, type: string) {
    const value = event.value.trim();

    switch (type) {
      case 'to':
        // Remove email if it no longer has a valid address
        if (!value || !this.isValidEmail(value)) {
          this.remove(email, type);
          return;
        }

        // Edit existing email
        const index = this.toEmails.indexOf(email);
        if (index >= 0) {
          this.toEmails[index].address = value;
        }

        break;
      case 'cc':
        // Remove email if it no longer has a valid address
        if (!value || !this.isValidEmail(value)) {
          this.remove(email, type);
          return;
        }

        // Edit existing email
        const ccIndex = this.ccEmails.indexOf(email);
        if (ccIndex >= 0) {
          this.ccEmails[ccIndex].cc = value;
        }
        break;
      case 'bcc':
        // Remove email if it no longer has a valid address
        if (!value || !this.isValidEmail(value)) {
          this.remove(email, type);
          return;
        }

        // Edit existing email
        const bccIndex = this.toEmails.indexOf(email);
        if (bccIndex >= 0) {
          this.bccEmails[bccIndex].bcc = value;
        }
        break;
    }
  }

  // Helper method to validate email addresses
  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  }
}
