<div class="relative inline-flex flex-col h-full">
  <div class="flex flex-row justify-between items-center mb-7 bg-[#121826] p-4">
    <div class="flex gap-1">
      <!-- <mat-icon
          class="text-primary-600"
          svgIcon="mat:shopping_cart"></mat-icon> -->
      <h2 class="font-bold text-lg text-white">Shopping Cart</h2>
    </div>
    <!-- <p
      *ngIf="hasCartItemsOrQuotationDetails() && !licenseAgreementCart"
      class="m-0 underline text-white font-normal text-sm cursor-pointer"
      (click)="onClearCart()">
      Remove all
    </p> -->
    <p *ngIf="!isCartEmpty()">
      <button
        class="!text-white"
        mat-icon-button
        type="button"
        (click)="cancel()"
        aria-label="close"
        matTooltip="Close Cart">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </p>
  </div>
  <div
    *ngIf="isLoading(); else cartItems"
    class="h-full flex flex-col justify-center px-5">
    <vex-app-loader></vex-app-loader>
  </div>
</div>

<ng-template #cartItems>
  <div
    class="h-full flex-col justify-between bg-[White] overflow-y-auto relative">
    <div class="flex flex-col gap-4 px-4">
      <div
        class="agreement-cart-section border border-black rounded-lg px-4 pt-4 bg-gray-100"
        *ngIf="
          cartData &&
          cartData?.AgreementItem &&
          cartData?.AgreementItem.length > 0 &&
          hasCartItemsAgreementDetails()
        ">
        <div class="flex items-center">
          <h4 class="font-semibold text-1xl">
            {{ "License Agreement" }}
          </h4>
          <hr class="flex-grow border-t border-gray-300 ml-4" />
        </div>
        <div
          *ngFor="let item of cartData?.AgreementItem; let last = last"
          class="gap-4">
          <div
            class="flex flex-row justify-between items-center"
            *ngIf="item.isQuotation">
            <h5 class="text-base">Quotation Number</h5>
            <h5 class="text-base">
              {{ item.quotationDetails.QuotationNumber }}
            </h5>

            <!-- <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matTooltip]="'PO Number'"
              [matMenuTriggerData]="{ quotationDetails: item.quotationDetails }"
              (click)="$event.stopPropagation()">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button> -->
          </div>
          <vex-cart-items
            [item]="item"
            (itemChecked)="itemChecked($event)"
            (isLoading)="onIsLoading($event)"
            [showCheckBox]="false"
            [showPO]="item.isQuotation"
            [PONumber]="
              item.isQuotation ? item?.quotationDetails?.PONumber : ''
            "></vex-cart-items>

          <mat-divider *ngIf="!last" class="my-4"></mat-divider>
          <div class="w-full my-2">
            <table class="w-full p-4">
              <tr>
                <td class="text-left">
                  <span class="text-left text-black font-semibold text-lg">
                    Total Outstanding:</span
                  >
                  <br />
                </td>
                <td class="text-right">
                  <span class="text-right text-black font-semibold text-lg">
                    {{
                      item.licenseAgreementCartItemTotalPriceDue
                        | currency: "USD" : "symbol" : "1.0-0"
                    }}</span
                  >
                </td>
              </tr>
            </table>
            <mat-divider class="my-4"></mat-divider>
            <div class="sm:flex flex-col gap-2 block justify-between p-4">
              <button
                *ngIf="item.isQuotation"
                class="rounded-sm !bg-white !text-black w-full"
                mat-stroked-button
                (click)="onAddQuotationNo(item?.quotationDetails)">
                <mat-icon svgIcon="mat:edit"></mat-icon>
                <span>
                  {{ "PO Number" }}
                </span>
              </button>
              <button
                color="primary"
                class="rounded-sm w-full"
                (click)="onAgreementCheckout(item)"
                mat-flat-button>
                License Agreement Checkout
              </button>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="hasSampleDueCartItems()">
        <div
          class="agreement-cart-section border border-black rounded-lg px-4 pt-4 bg-gray-100"
          *ngFor="
            let SampleDueItem of cartData?.sampleDueDetails;
            let last = last
          ">
          <div class="flex items-center">
            <h4 class="font-semibold text-1xl">
              {{ "Sample Fee ( Prior Due )" }}
            </h4>
            <hr class="flex-grow border-t border-gray-300 ml-4" />
          </div>
          <div class="gap-4">
            <ng-container
              *ngFor="
                let cartItem of SampleDueItem.cartItems;
                let lastCartItem = last
              ">
              <vex-cart-items
                [item]="cartItem"
                (itemChecked)="itemChecked($event)"
                (isLoading)="onIsLoading($event)"
                [showCheckBox]="false"
                [showDelete]="false"></vex-cart-items>

              <mat-divider *ngIf="!lastCartItem" class="my-4"></mat-divider>
            </ng-container>
            <div class="w-full my-2">
              <table class="w-full p-4">
                <tr>
                  <td class="text-left">
                    <span class="font-medium text-base">
                      Late Payment Finance Charge:</span
                    >
                    <br />
                  </td>
                  <td class="font-medium text-right text-black text-base">
                    {{
                      SampleDueItem.sampleLateFee
                        | currency: "USD" : "symbol" : "1.0-0"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <span class="text-left text-black font-semibold text-lg">
                      Total Outstanding:</span
                    >
                    <br />
                  </td>
                  <td class="text-right">
                    <span class="text-right text-black font-semibold text-lg">
                      {{
                        SampleDueItem.sampleDueCartTotalFee
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}</span
                    >
                  </td>
                </tr>
              </table>
              <mat-divider class="my-4"></mat-divider>
              <div class="sm:flex flex-col gap-2 block justify-between p-4">
                <button
                  color="primary"
                  class="rounded-sm w-full"
                  mat-flat-button
                  type="button"
                  (click)="sampleDueCheckout(SampleDueItem)">
                  Sample Due Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        class="flex flex-col gap-4"
        *ngIf="
          cartData &&
          cartData?.quotationDetails &&
          cartData?.quotationDetails.length != 0
        ">
        <mat-accordion
          *ngFor="
            let quotationDetails of cartData?.quotationDetails;
            let i = index
          ">
          <mat-expansion-panel
            [expanded]="expandedPanels[quotationDetails.QuotationNumber]">
            <mat-expansion-panel-header
              class="expansion-header-custom"
              (click)="togglePanel(quotationDetails.QuotationNumber)">
              <mat-panel-title class="flex flex-row justify-between">
                <h5 class="text-base">Quotation Number</h5>
                <h5 class="text-base">
                  {{ quotationDetails.QuotationNumber }}
                </h5>

                <!-- <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{
                  quotationDetails: quotationDetails
                }"
                [matTooltip]="'PO Number'"
                (click)="$event.stopPropagation()">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button> -->
                <mat-icon
                  [svgIcon]="
                    expandedPanels[quotationDetails.QuotationNumber]
                      ? 'mat:expand_less'
                      : 'mat:expand_more'
                  "></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              *ngFor="let item of quotationDetails?.cartItems; let last = last"
              class="gap-4">
              <vex-cart-items
                [item]="item"
                (itemChecked)="itemChecked($event)"
                (isLoading)="onIsLoading($event)"
                [showCheckBox]="false"
                [showDelete]="false"
                [showPO]="true"
                [PONumber]="quotationDetails.PONumber"></vex-cart-items>

              <div
                *ngIf="last"
                class="sm:flex sm:gap-4 gap-2 block justify-between p-4">
                <button
                  class="rounded-sm !bg-white !text-black w-full sm:mb-4"
                  mat-stroked-button
                  (click)="onAddQuotationNo(quotationDetails)"
                  type="button"
                  aria-label="PO Number"
                  [matTooltip]="'Add purchase order number'">
                  <mat-icon svgIcon="mat:edit" iconPositionStart></mat-icon>
                  <span>
                    {{ "PO Number" }}
                  </span>
                </button>
                <button
                  color="primary"
                  class="rounded-sm w-full"
                  (click)="checkoutQuot(quotationDetails.id)"
                  type="button"
                  aria-label="Checkout"
                  mat-flat-button>
                  Checkout
                </button>
              </div>
              <!-- Only show the divider if this is not the last item -->
              <mat-divider *ngIf="!last" class="my-4"></mat-divider>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- <div class="px-4">
        <div class="flex items-center" *ngIf="hasCartItemsOrQuotationDetails()">
          <h4 class="font-semibold text-1xl">
            {{ "Samples" }}
          </h4>
          <hr class="flex-grow border-t border-gray-300 ml-4" />
        </div>
        <div
          class="cart-cards"
          *ngIf="
            cartData && cartData?.cartItems && cartData?.cartItems.length != 0
          ">
          <div
            *ngFor="let item of cartData?.cartItems; let last = last"
            class="gap-4">
            <vex-cart-items
              [item]="item"
              (itemChecked)="itemChecked($event)"
              (isLoading)="onIsLoading($event)"
              [showCheckBox]="cartData?.cartItems.length > 1"></vex-cart-items>
            <mat-divider *ngIf="!last" class="my-4"></mat-divider>
          </div>
        </div>
      </div> -->

      <div
        class="agreement-cart-section border border-black rounded-lg px-4 pt-4 bg-gray-100"
        *ngIf="hasCartItemsDetails()">
        <div class="flex items-center">
          <h4 class="font-semibold text-1xl">
            {{ "Sample Fee ( New )" }}
          </h4>
          <hr class="flex-grow border-t border-gray-300 ml-4" />
        </div>
        <div
          *ngFor="let item of cartData?.cartItems; let last = last"
          class="gap-4">
          <!-- <div
          class="flex flex-row justify-between items-center"
          *ngIf="item.isQuotation">
          <h5 class="text-base">Quotation Number</h5>
          <h5 class="text-base">
            {{ item.quotationDetails.QuotationNumber }}
          </h5>
        </div> -->
          <vex-cart-items
            [item]="item"
            (itemChecked)="itemChecked($event)"
            (isLoading)="onIsLoading($event)"
            [showCheckBox]="cartData?.cartItems.length > 1"></vex-cart-items>

          <mat-divider *ngIf="!last" class="my-4"></mat-divider>
        </div>
        <div class="w-full my-2" *ngIf="hasCartItemsDetails()">
          <table class="w-full">
            <tr>
              <td class="text-left">
                <span class="font-semibold text-lg">Total Outstanding:</span>
                <br />
                <span
                  class="font-medium text-sm text-gray-400"
                  *ngIf="onShowSelectItemsCount()">
                  <span>
                    ({{ selectedCartItems.length }}/{{
                      cartData?.cartItems.length
                    }})
                    {{
                      selectedCartItems.length === 1
                        ? " has been selected"
                        : " have been selected"
                    }}
                  </span>
                </span>
              </td>
              <td class="font-semibold text-right text-black text-lg">
                {{ totalPrice | currency: "USD" : "symbol" : "1.0-0" }}
              </td>
            </tr>
          </table>
          <mat-divider class="my-4"></mat-divider>
          <div class="sm:flex gap-2 block justify-between p-4 button-container">
            <!-- <button
              class="sm:mb-2 rounded-sm !bg-white !text-gray-400 w-full"
              (click)="cancel()"
              mat-stroked-button
              type="button">
              Cancel
            </button> -->
            <button
              color="primary"
              class="rounded-sm w-full"
              (click)="checkout()"
              mat-flat-button
              type="button">
              Checkout
            </button>
          </div>
        </div>
      </div>

      <div
        class="min-h-screen flex flex-col justify-center items-center overflow-hidden"
        *ngIf="isCartEmpty()">
        <div>
          <div class="flex flex-col justify-center items-center gap-4">
            <div class="font-bold">Your cart is empty</div>
            <button
              color="primary"
              class="rounded-sm"
              (click)="cancel()"
              mat-flat-button>
              Click to continue adding samples
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
    class="bottom-0 bg-gray-100 border-t border-gray-500 w-full p-4"
    *ngIf="hasCartItemsDetails()">
    <table class="w-full">
      <tr>
        <td class="text-left">
          <span class="font-semibold text-lg">Total Outstanding:</span> <br />
          <span
            class="font-medium text-sm text-gray-400"
            *ngIf="onShowSelectItemsCount()">
            <span>
              ({{ selectedCartItems.length }}/{{ cartData?.cartItems.length }})
              {{
                selectedCartItems.length === 1
                  ? " has been selected"
                  : " have been selected"
              }}
            </span>
          </span>
        </td>
        <td class="font-semibold text-right text-black text-lg">
          {{ totalPrice | currency: "USD" : "symbol" : "1.0-0" }}
        </td>
      </tr>
    </table>
    <mat-divider class="my-4"></mat-divider>
    <div class="sm:flex gap-2 block justify-between p-4 button-container">
      <button
        class="sm:mb-2 rounded-sm !bg-white !text-gray-400 w-full"
        (click)="cancel()"
        mat-stroked-button
        type="button">
        Cancel
      </button>
      <button
        color="primary"
        class="rounded-sm w-full"
        (click)="checkout()"
        mat-flat-button
        type="button">
        Checkout
      </button>
    </div>
  </div> -->
</ng-template>

<!-- <mat-menu #menu="matMenu">
  <ng-template matMenuContent let-quotationDetails="quotationDetails">
    <button mat-menu-item (click)="onAddQuotationNo(quotationDetails)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>
        {{
          quotationDetails?.PONumber ? "Update PO Number" : "Enter PO Number"
        }}
      </span>
    </button>
  </ng-template>
</mat-menu> -->
